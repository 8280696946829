import { Entity, pStore } from './Entity';
import { StateMachineState } from './StateMachineState';
import { OrderCustomer } from './OrderCustomer';

export const Order = {

    /**
     * @type {string}
     */
    get id() {
        return this.value?.id;
    },

    /**
     * @type {string}
     */
    get orderNumber() {
        return this.value?.orderNumber;
    },

    /**
     * @type {string}
     */
    get currencyId() {
        return this.value?.currencyId;
    },

    /**
     * @type {OrderCustomer}
     */
    get orderCustomer() {
        return this.value?.orderCustomer;
    },

    /**
     * @type {StateMachineState}
     */
    get stateMachineState() {
        return this.value?.stateMachineState;
    },

    fill(value) {
        value.orderCustomer = this[pStore].createOrUpdate(value.orderCustomer);
        value.stateMachineState = this[pStore].createOrUpdate(value.stateMachineState);

        return super.fill(value);
    },

    __proto__: Entity,
};

export default Order;
