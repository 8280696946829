import { Entity } from './Entity';

export const StateMachineState = {

    /**
     * @type {string}
     */
    get id() {
        return this.value?.id;
    },

    /**
     * @type {string}
     */
    get name() {
        return this.value?.translated?.name ?? this.value?.name;
    },

    /**
     * @type {string}
     */
    get technicalName() {
        return this.value?.technicalName;
    },

    /**
     * @type {string}
     */
    get stateMachineId() {
        return this.value?.stateMachineId;
    },

    __proto__: Entity,
};

export default StateMachineState;
