import { Entity, pStore } from './Entity';

export const Customer = {

    get email() {
        return this.value?.email;
    },

    get firstName() {
        return this.value?.firstName;
    },

    get lastName() {
        return this.value?.lastName;
    },

    get guest() {
        return this.value?.guest;
    },

    get customerNumber() {
        return this.value?.customerNumber;
    },

    get salutation() {
        return this[pStore].get(this.value?.salutationId);
    },

    __proto__: Entity,
};

export default Customer;
