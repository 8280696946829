import { Page, defaultViewFactory, createView } from '../prototypes/Page';
import { BottomSheetManager } from '../managers/BottomSheet';
import { Shopware } from '../managers/Shopware';
import { CartManager } from '../managers/Cart';
import { PersistentFiltersManager } from '../managers/PersistentFilters';

export const CompletePage = {
    name: 'CompletePage',
    path: ['/complete', '/complete/{orderId}'],
    template: 'page-complete',
    busy: false,
    paymentFailed: false,
    orderId: null,
    order: null,
    context: null,

    get orderCanceled() {
        return this.order?.stateMachineState?.technicalName === 'cancelled';
    },

    onRouteEnter(path, params) {
        super.onRouteEnter();

        // if we enter the first route but the URL is actually the second round,
        // we abort and wait for the second event.
        if (path === this.path[0] && path !== location.hash.substr(2)) {
            return;
        }

        this.busy = true;
        this.order = null;
        this.orderId = null;

        Object.assign(this, params);

        const task = Shopware.context
            .then(context => {
                if (!context.customer) {
                    return Promise.reject();
                }

                if (!context.paymentMethod) {
                    return Promise.reject('#!/payment');
                }

                if (this.orderId) {
                    return Shopware.order(this.orderId);
                }

                if (CartManager.productCount === 0){
                    return Promise.reject();
                }

                return Shopware.order();
            }).then(order => {
                this.orderId = order.id;
                this.order = order;
                location.replace(`#!/complete/${order.id}`);

                if (this.handlePaymentFailure()) {
                    return { redirectUrl: null };
                }

                return Shopware.handlePayment(order.id, `${location.href}?payment=failed`, location.href);
            }).then(payment => {
                if (!payment.redirectUrl) {
                    this.busy = false;

                    return this.scope.update();
                }

                location.replace(payment.redirectUrl);
            }).catch(reason => {
                const target = (typeof reason === 'string') ? reason : '#!/cart';

                location.replace(target);
            });

        BottomSheetManager.request(this);
        BottomSheetManager.busy(this, task);
    },

    handlePaymentFailure() {
        if (!PersistentFiltersManager.externalUrlParams.has('payment')) {
            return false;
        }

        this.paymentFailed = true;
    },

    onRouteLeave() {
        super.onRouteLeave();

        BottomSheetManager.relase(this);
    },

    __proto__: Page,
};

createView((page) => {
    return {
        strings: {
            finishMessage: 'checkout.finishHeader',
            shopName: 'hotWaiter.shop.title',
            paymentFailed: 'hotWaiter.error.paymentFailed',
            orderCanceled: 'hotWaiter.error.orderCanceled'
        },

        get orderNumber() {
            return page.order?.orderNumber;
        },

        get shopName() {
            return this.l10n(this.strings.shopName);
        },

        get headerIcon() {
            if (page.busy) {
                return 'basket';
            }

            if (page.paymentFailed || page.orderCanceled) {
                return 'warning';
            }

            return 'complete';
        },

        get statusMessage() {
            if (page.paymentFailed) {
                return `${this.l10n(this.strings.paymentFailed)}<br>${this.l10n(this.strings.orderCanceled, this.finishVars)}`;
            }

            if (page.orderCanceled) {
                return this.l10n(this.strings.orderCanceled, this.finishVars);
            }

            return this.l10n(this.strings.finishMessage, this.finishVars);
        },

        get finishVars() {
            return {
                shop: this.shopName,
                orderNumber: this.orderNumber
            };
        },

        __proto__: defaultViewFactory(page),
    };
}, CompletePage);
