import { Entity } from './Entity';

export const CartQuantityInformation = {

    get maxPurchase() {
        return this.value?.maxPurchase;
    },

    get minPurchase() {
        return this.value?.minPurchase;
    },

    get purchaseSteps() {
        return this.value?.purchaseSteps;
    },

    __proto__: Entity,
};

export default CartQuantityInformation;
