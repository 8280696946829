import { Entity, pStore } from './Entity';

export const ProductGroup = {
    get name() {
        return this.value?.translated?.name ?? this.value?.name;
    },

    get description() {
        return this.value?.translated?.description ?? this.value?.description;
    },

    get displayType() {
        return this.value?.displayType;
    },

    get filterable() {
        return this.value?.filterable;
    },

    get options() {
        return this[pStore].lazyCollection(this.value?.options);
    },

    __proto__: Entity,
};

export default ProductGroup;
