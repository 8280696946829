import { HTMLElement } from 'application-frame/core/nativePrototype';
import DataBinding from '@af-modules/databinding';

import defineCustomElement from '../../lib/defineCustomElement';
import template from './template';

const HotIconButtonMeta = {
    name: 'hot-icon-button',
    template,
    get object() { return HotIconButton; },
};

const meta = HotIconButtonMeta;
const pScope = Symbol('HotButton.scope');
const pView = Symbol('HotButton.view');

const createView = function() {
    return {};
};

const HotIconButton = {
    constructor: function HotHeader() {
        const instance = HTMLElement.constructor.apply(this);

        instance._create();

        return instance;
    },

    _create() {
        const view = createView(this);
        const { scope, node } = DataBinding.createTemplateInstance({ template, scope: view });

        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(node);

        this[pScope] = scope;
        this[pView] = view;

        DataBinding.attachBindings(scope, this, [
            { selector: 'root', name: 'bind-ripple', value: '#ripple' }
        ]);
    },

    connectedCallback() {
        this.setAttribute('resolved', '');
    },

    __proto__: HTMLElement,
};

defineCustomElement(meta);

export { HotIconButton, HotIconButtonMeta };
