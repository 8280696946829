import { HTMLElement } from 'application-frame/core/nativePrototype';
import { RenderEngine } from 'application-frame/rendering';
import DataBinding from '@af-modules/databinding';

import defineCustomElement from '../../lib/defineCustomElement';
import template from './template';

const HotAccordionMeta = {
    name: 'hot-accordion',
    template,
    attributes: ['open'],
    get object() { return HotAccordion; },
};

const meta = HotAccordionMeta;
const pScope = Symbol('HotButton.scope');
const pView = Symbol('HotButton.view');
const pWasOpen = Symbol('HotButton.wasOpen');

const createView = function(element) {
    return {
        get isOpen() { return element.open; },

        get shouldClose() {
            if (!element[pWasOpen]) {
                return null;
            }

            return !element.open;
        },

        onToggle() {
            element.open = !element.open;
        },

        animateOpen(targetElement) {
            return this.animateState(targetElement, 'open');
        },

        animateClosed(targetElement) {
            return this.animateState(targetElement, 'closed');
        },

        animateState(targetElement, state) {
            const counterState = state === 'open' ? 'closed' : 'open';

            return new Promise(resolve => {
                const contentHeight = targetElement.offsetHeight;

                targetElement.classList.add(counterState);

                RenderEngine.scheduleRenderTask(() => {
                    element.style.setProperty('--content-height', contentHeight);

                    targetElement.classList.add(state);
                    targetElement.classList.remove(counterState);

                    targetElement.addEventListener('transitionend', () => {
                        targetElement.classList.remove(state);
                        resolve();
                    }, { once: true });
                });
            });
        }
    };
};

const HotAccordion = {
    get open() {
        return this.hasAttribute('open');
    },

    set open(value) {
        value ? this.setAttribute('open', '') : this.removeAttribute('open');
    },

    constructor: function HotAccordion() {
        const instance = HTMLElement.constructor.apply(this);

        instance._create();

        return instance;
    },

    _create() {
        const view = createView(this);
        const { scope, node } = DataBinding.createTemplateInstance({ template, scope: view });

        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(node);

        this[pScope] = scope;
        this[pView] = view;
    },

    connectedCallback() {
        this.setAttribute('resolved', '');
    },

    attributeChangedCallback(name) {
        if (name === 'open') {
            const eventName = this.hasAttribute('open') ? 'open' : 'close';

            this[pWasOpen] = true;
            this.dispatchEvent(new CustomEvent(eventName, { bubbles: true }));
        }

        this[pScope].update();
    },

    __proto__: HTMLElement,
};

defineCustomElement(meta);

export { HotAccordion, HotAccordionMeta };
