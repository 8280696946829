import { Router } from '../lib/Router';
import { BottomSheet } from '../components/BottomSheet';
import { getTemplate } from '../lib/getTemplate';

const Callbacks = {
    onComponentClose: Symbol('BottomSheetManager.Callbacks.onComponentClose'),
    onBusyTaskDone: Symbol('BottomSheetManager.Callbacks.onBusyTaskDone'),
};

export const BottomSheetManager = {
    component: null,
    contentComponent: null,

    init() {
        this.component = BottomSheet.new();
        this.component.init(getTemplate(BottomSheet.template));
        this.component.observeClose(this[Callbacks.onComponentClose].bind(this));
    },

    request(component) {
        this.contentComponent = component;
        this.component.setContent(component.nodes);
        this.component.open = true;
        this.component.busy = false;
    },

    relase(component) {
        if (this.contentComponent !== component) {
            return;
        }

        this.component.open = false;
        this.contentComponent = null;
    },

    busy(component, promise) {
        if (this.contentComponent !== component) {
            throw new Error(`Component ${component.name} does not currently own bottom sheet`);
        }

        const callback = this[Callbacks.onBusyTaskDone].bind(this, component);

        this.component.busy = true;
        promise.then(callback, callback);
    },

    [Callbacks.onComponentClose]() {
        if (!this.contentComponent) {
            return;
        }

        const paths = Array.isArray(this.contentComponent.path) ? this.contentComponent.path : [this.contentComponent.path];
        const fullPaths = paths.map(path => new RegExp(`#!${path.replace(/\{[^}]+\}/g, '([^/]+)')}`));
        const currentPath = Router.getCurrentPath().join('/');

        if (!fullPaths.some(path => currentPath.match(path))) {
            return;
        }

        Router.switchTo('/');
        this.contentComponent = null;
    },

    [Callbacks.onBusyTaskDone](component) {
        if (this.contentComponent !== component) {
            return;
        }

        this.component.busy = false;
    }
};

export default BottomSheetManager;
