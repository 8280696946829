import DataStorage from 'application-frame/core/DataStorage';

import TypeMap from './TypeMap';

const pStore = Symbol('AggregationCollection.store');
const aggregationMap = new WeakMap();

export const Aggregations = {
    Manufacturer: 'manufacturer',
    Price: 'price',
    ShippingFree: 'shipping-free',
    Rating: 'rating',
    Properties: 'properties',
};

export const AggregationCollection = {
    Aggregations,

    [pStore]: null,

    get(aggregationName) {
        const aggregation = this.value?.get(aggregationName);

        if (!aggregation) {
            return null;
        }

        if (!aggregationMap.has(aggregation)) {
            const entity = TypeMap[aggregation.apiAlias]?.new(aggregation, this[pStore]);

            if (!entity) {
                throw new TypeError(`unknown entity type ${aggregation.apiAlias}`);
            }

            aggregationMap.set(aggregation, entity);
        }

        return aggregationMap.get(aggregation);
    },

    new(dictionary = {}, entityStore) {
        const instance = ({ [pStore]: entityStore, __proto__: this }).constructor();

        if (dictionary) {
            instance.fill(dictionary);
        }

        return instance;
    },

    fill(value) {
        const map = new Map(Object.entries(value));

        return super.fill(map);
    },

    __proto__: DataStorage,
};

export default AggregationCollection;
