import EventTarget from 'application-frame/core/EventTarget';
import { SubscriberFeature } from 'application-frame/core/features/SubscriberFeature';
import Shopware from './Shopware';
import { Entity } from '../lib/shopware/Entity';
import { Cart } from '../lib/shopware/Cart';
import { EntityCollection } from '../lib/shopware/EntityCollection';
import { CartLineItem } from '../lib/shopware/CartLineItem';
import { LanguageManager } from './Language';
import { Iterator } from '../lib/Iterator';

const pCart = Symbol('CartManager.carts');
const pPending = Symbol('CartManager.pending');

const Callbacks = {
    onCartUpdated: Symbol('CartManager.Callbacks.onCartUpdated'),
};

const innerCart = function(manager) {
    return Promise.resolve(manager[pCart] ?? manager[pPending] ?? Promise.reject(new Error('cart is unavailbale!')));
};

export const CartManager = {

    /**
     * @type {Cart}
     */
    [pCart]: null,

    /**
     * @type {(Promise<Cart>|null)}
     */
    [pPending]: null,

    Events: {
        Count: Symbol('CartManager.Events.Count'),
        Update: Symbol('CartManager.Events.Update'),
    },

    /**
     * @type {number}
     */
    get productCount() {
        return this[pCart]?.totalCartCount ?? 0;
    },

    /**
     * @type {(EntityCollection|null)}
     */
    get lineItems() {
        return this[pCart]?.items;
    },

    get price() {
        return this[pCart]?.price;
    },

    /**
     * @param {Entity} entity
     * @param {number} quantity
     *
     * @return {undefined}
     */
    add(entity, quantity = 1) {
        return innerCart(this).then(cart => {
            const lineItem = Iterator.new(cart.items).find(item => item.referencedId === entity.id);

            lineItem ? cart.update(lineItem, quantity) : cart.add(entity, quantity);
            this.emit(this.Events.Count);
        });
    },

    /**
     * @param {CartLineItem} lineItem
     * @param {number} quantity
     * @param {string} referencedId
     *
     * @return {undefined}
     */
    update(lineItem, quantity, referencedId) {
        return innerCart(this).then(cart => {
            cart.update(lineItem, quantity, referencedId);

            if (quantity > 0 || quantity < 0) {
                this.emit(this.Events.Count);
            }
        });
    },

    /**
     * @param  {CartLineItem} lineItem
     *
     * @return {undefined}
     */
    remove(lineItem) {
        return innerCart(this).then(cart => {
            cart.remove(lineItem);
            this.emit(this.Events.Count);
        });
    },

    init() {
        this.constructor();

        this[pPending] = Shopware.cart().then(cart => {
            this[pCart] = cart;

            cart.when(this[Callbacks.onCartUpdated].bind(this));
        });

        SubscriberFeature(LanguageManager, this);
    },

    [LanguageManager.Events.Change]() {
        if (!this[pCart]) {
            return;
        }

        Shopware.cart().then(() => this.emit(this.Events.Update));
    },

    [Callbacks.onCartUpdated]() {
        this.emit(this.Events.Update);
        this.emit(this.Events.Count);
    },

    __proto__: EventTarget,
};

export default CartManager;
