import EventTarget from 'application-frame/core/EventTarget';

import EntityStore from './EntityStore';
import { UploadableEntityCallbacks } from './UploadableEntity';
import Listing from './Listing';
import Cart from './Cart';
import { request, BaseApi } from './request';

const Navigations = {
    Main: 'main-navigation',
    Service: 'service-navigation',
    Footer: 'footer-navigation',
};

const pContextToken = Symbol('ShopwareApi.contextToken');

const Events = {
    ContextChanged: Symbol('ShopwareApi.Events.ContextChanged'),
};

export const ShopwareApi = {
    Navigations,
    Events,

    key: null,
    host: null,
    version: 3,
    languageId: null,
    entityStore: null,
    [pContextToken]: null,

    get contextToken() {
        return this[pContextToken];
    },

    set contextToken(value) {
        this.emit(Events.ContextChanged, { oldToken: this[pContextToken], newToken: value });
        this[pContextToken] = value;
    },

    navigation(rootId, activeId = null, params = null) {
        if (!activeId) {
            activeId = rootId;
        }

        return request(`/navigation/${activeId}/${rootId}`, { data: params, api: this })
            .then(responseData => {
                return this.entityStore.collection(responseData);
            });
    },

    listing(categoryId) {
        return Listing.new(categoryId, this.entityStore);
    },

    context(update = null) {
        const method = update ? 'PATCH' : 'GET';

        return request('/context', { api: this, method, data: update })
            .then(responseData => {
                if (responseData.apiAlias !== 'sales_channel_context') {
                    return this.context();
                }

                return this.entityStore.createOrUpdate(responseData);
            });
    },

    product(context, id, options = []) {
        const idKey = options.length ? 'product.parentId' : 'product.id';

        const data = {
            filter: [{ type: 'equals', field: idKey, value: id }],
            limit: 1,
        };

        options.forEach(optionId => data.filter.push({ type: 'equals', field: 'optionIds', value: optionId }));

        return request(`/product-listing/${context.salesChannel.navigationCategory.id}`, { data, method: 'POST', api: this })
            .then(listingData => listingData.elements[0] ?? Promise.reject(new Error(`product ${id} does not exists`)))
            .then(productData => this.entityStore.createOrUpdate(productData));
    },

    language(id) {
        const data = {
            limit: 1,
            filter: [{ type: 'equals', field: 'id', value: id }]
        };

        return request('/language', { data, api: this })
            .then(data => data[0] ?? Promise.reject(new Error(`language ${id} does not exist`)))
            .then(language => this.entityStore.createOrUpdate(language));
    },

    languages(params) {
        return request('/language', { data: params, api: this })
            .then(data => this.entityStore.collection(data));
    },

    productOptions(productId) {
        const data = {
            associations: {
                children: {
                    associations: {
                        options: {
                            associations: {
                                group: [''],
                            },
                        },
                    },
                },
            },
            includes: {
                product: ['children', 'options'],
            },
        };

        return request(`/product/${productId}`, { data, base: BaseApi.SalesChannel, api: this })
            .then(({ data }) => {
                const options = data.children
                    .flatMap(child => child.options)
                    .reduce((map, item) => map.has(item.id) ? map : (map.set(item.id, item), map), new Map())
                    .values();

                return this.entityStore.collection(options);
            });
    },

    /**
     * @param {{ id: string, type: string, quantity: number, referenceId: string }[]} [items=[]]
     * @return {Promise<Cart>}
     */
    cart(items = []) {
        const handleCartData = cartData => {
            return this.entityStore.createOrUpdate(cartData);
        };

        if (items.length === 0) {
            return request('/checkout/cart', { api: this })
                .then(handleCartData);
        }

        const lineItemPath = '/checkout/cart/line-item';
        const isAdd = items.some(item => item.quantity || item.referenceId);
        const isUpdate = items.some(item => item.id);

        if (!isAdd && isUpdate) {
            const ids = items.map(item => item.id);

            return request(lineItemPath, { data: { ids }, method: 'DELETE', api: this })
                .then(handleCartData);
        }

        const method = isAdd && isUpdate ? 'PATCH' : 'POST';

        return request(lineItemPath, { data: { items }, method, api: this })
            .then(handleCartData);
    },

    new(host, key, languageId = null) {
        const entityStore = EntityStore.new();
        const instance = ({ host, key, languageId, entityStore, __proto__: this }).constructor();

        Object.values(EntityStore.Events).forEach(event => {
            if (!instance[event]) {
                return;
            }

            entityStore.on(event, instance[event].bind(instance));
        });

        return instance;
    },

    availablePaymentMethods() {
        return request('/payment-method', { api: this, data: { onlyAvailable: true } })
            .then(paymentMethods => this.entityStore.lazyCollection(paymentMethods));
    },

    availableShippingMethods() {
        return request('/shipping-method', { api: this, data: { onlyAvailable: true } })
            .then(paymentMethods => this.entityStore.lazyCollection(paymentMethods));
    },

    account(data) {
        const handleAccountData = accountData => this.entityStore.createOrUpdate(accountData);

        if (!data) {
            return request('/account/customer', { api: this }).then(handleAccountData);
        }

        if (!data.salutationId) {
            if (data.email && data.emailConfirmation && data.password) {
                return request('/account/change-email', { api: this, data, method: 'POST' })
                    .then(handleAccountData);
            }

            if (data.password && data.newPassword && data.newPasswordConfirm) {
                return request('/account/change-password', { api: this, data, method: 'POST' })
                    .then(handleAccountData);
            }

            if (data.username && data.password) {
                return request('/account/login', { api: this, data, method: 'POST' })
                    .then(handleAccountData);
            }

            if (data.logout) {
                return request('/account/logout', { api: this, data, method: 'POST' })
                    .then(handleAccountData);
            }
        }

        return request('/account/register', { api: this, data, method: 'POST' })
            .then(handleAccountData);
    },

    order(id) {
        const handleOrderData = orderData => this.entityStore.createOrUpdate(orderData);

        if (id) {
            return request('/order', { api: this, method: 'POST', data: {
                filter: [{ type: 'equals', field: 'id', value: id }]
            }}).then((result) => handleOrderData(result.orders.elements[0]));
        }

        return request('/checkout/order', { api: this, method: 'POST'}).then(handleOrderData);
    },

    handlePayment(orderId, errorUrl, finishUrl) {
        const data = { orderId, finishUrl, errorUrl };

        return request('/handle-payment', { api: this, method: 'POST', data });
    },

    salutations() {
        return request('/salutation', { api: this }).then(data => this.entityStore.lazyCollection(data));
    },

    cms(pageId) {
        return request(`/cms/${pageId}`, { api: this, method: 'POST' })
            .then(data => this.entityStore.createOrUpdate(data));
    },

    [EntityStore.Events.Upload]({ entity }) {
        const onUpload = UploadableEntityCallbacks.onUpload;

        if (!entity[onUpload]) {
            throw new Error('unable to upload entity!');
        }

        entity[onUpload](this);
    },

    [EntityStore.Events.Refresh]({ type, parameter, entity }) {
        if (type === Listing) {
            parameter.associations = { properties: {} };
            parameter.filter = entity.filters;
            parameter['post-filter'] = entity.postFilters;

            return request(`/product-listing/${entity.categoryId}`, { method: 'POST', data: parameter, api: this })
                .then(listingResult => entity.fill(listingResult));
        }

        throw new Error('Entity does not support refresh!');
    },

    __proto__: EventTarget,
};

export default ShopwareApi;
