import { BindingApi } from '@af-modules/databinding';

const RippleBinding = {

    name: 'bind-ripple',

    rippleSelector: null,
    node: null,
    active: false,

    get ripple() {
        const shadowRipple = this.node.shadowRoot?.querySelector(this.rippleSelector);
        const ripple = shadowRipple ?? this.node.querySelector(this.rippleSelector);

        if (!ripple) {
            console.error(this.node, 'does not contain the specified ripple!');
        }

        return ripple;
    },

    constructor({ text, node }) {
        this.rippleSelector = text;
        this.node = node;

        this.node.addEventListener('pointerdown', this.onStartRipple.bind(this));

        window.addEventListener('pointercancel', this.onEndRipple.bind(this));
        window.addEventListener('pointerup', this.onEndRipple.bind(this));

        this.node.addEventListener('focus', this.onFocusRipple.bind(this));
        this.node.addEventListener('blur', this.onBlurRipple.bind(this));

        this.node.addEventListener('pointerover', this.onStartHover.bind(this));
        this.node.addEventListener('pointerleave', this.onEndHover.bind(this));

        BindingApi(this).attachBinding(this);
    },

    _make(...args) {
        this.constructor(...args);
    },

    update() {},

    onStartRipple(event) {
        const ripple = this.ripple;

        if (!ripple) {
            return;
        }

        this.active = true;
        ripple.startPress(event);
    },

    onEndRipple(event) {
        if (!this.active) {
            return;
        }

        const ripple = this.ripple;

        if (!ripple) {
            return;
        }

        this.active = false;
        ripple.endPress(event);
    },

    onFocusRipple(event) {
        const ripple = this.ripple;

        if (!ripple || ripple.fgActivation) {
            return;
        }

        ripple.startFocus(event);
    },

    onBlurRipple(event) {
        const ripple = this.ripple;

        if (!ripple) {
            return;
        }

        ripple.endFocus(event);
    },

    onStartHover(event) {
        const ripple = this.ripple;

        if (!ripple || event.pointerType === 'touch') {
            return;
        }

        ripple.startHover(event);
    },

    onEndHover(event) {
        const ripple = this.ripple;

        if (!ripple || event.pointerType === 'touch') {
            return;
        }

        ripple.endHover(event);
    },

    __proto__: BindingApi().Binding,
};

BindingApi().registerBinding(RippleBinding);

export default RippleBinding;
