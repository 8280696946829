import { Entity, pStore } from './Entity';

export const Category = {
    get parentId() {
        return this.value?.parentId;
    },

    get autoIncrement() {
        return this.value?.autoIncrement;
    },

    get mediaId() {
        return this.value?.mediaId;
    },

    get name() {
        return this.value?.translated?.name ?? this.value?.name;
    },

    get metaTitle() {
        return this.value?.translated?.metaTitle ?? this.value?.metaTitle;
    },

    get metaDescription() {
        return this.value?.translated?.metaDescription ?? this.value?.metaDescription;
    },

    get childCount() {
        return this.value?.childCount;
    },

    get cmsPageId() {
        return this.value?.cmsPageId;
    },

    get keywords() {
        return this.value?.translated?.keywords ?? this.value?.keywords;
    },

    get type() {
        return this.value?.type;
    },

    get children() {
        return this[pStore].lazyCollection(this.value?.children);
    },

    __proto__: Entity,
};

export default Category;
