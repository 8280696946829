import { Entity, pStore } from './Entity';

export const CmsPageImage = {
    get content() {
        return this.value?.content;
    },

    get mediaId() {
        return this.value?.mediaId;
    },

    get media() {
        return this[pStore].get(this.value?.mediaId);
    },

    fill(value) {
        if (value.media) {
            this[pStore].createOrUpdate(value.media);
        }

        super.fill(value);
    },

    __proto__: Entity,
};

export default CmsPageImage;
