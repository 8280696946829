import { prepareConstructor } from 'application-frame/core/nativePrototype';

export const defineCustomElement = function(meta) {
    if (window.ShadyCSS) {
        window.ShadyCSS.prepareTemplate(meta.template, meta.name);
    }

    prepareConstructor(meta.object);

    meta.object.constructor.observedAttributes = meta.attributes;

    if (window.customElements) {
        window.customElements.define(meta.name, meta.object.constructor);
    }
};

export default defineCustomElement;
