import ConfigData from '../../../config.json';

export const Config = {

    init() {},

    __proto__: ConfigData,
};

export default Config;
