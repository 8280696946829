import { Entity } from './Entity';

export const MediaThumbnail = {
    get width() {
        return this.value?.width;
    },

    get height() {
        return this.value?.height;
    },

    get url() {
        return this.value?.url;
    },

    get mediaId() {
        return this.value?.mediaId;
    },

    __proto__: Entity,
};

export default MediaThumbnail;
