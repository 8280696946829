import { Page, defaultViewFactory, createView } from '../prototypes/Page';
import { BottomSheetManager } from '../managers/BottomSheet';
import { Router } from '../lib/Router';
import { Shopware } from '../managers/Shopware';
import { Iterator } from '../lib/Iterator';

export const ContentPage = {
    name: 'FoodLabelsPage',
    path: '/cms/{contentId}',
    template: 'page-content',
    contentId: null,
    content: null,

    init(template) {
        return super.init(template);
    },

    onRouteEnter(path, params) {
        Object.assign(this, params);
        super.onRouteEnter();

        if (!this.contentId) {
            return Router.up();
        }

        const promise = Shopware.cms(this.contentId)
            .then(content => {
                this.content = content;
                this.scope.update();
            });

        BottomSheetManager.request(this);
        BottomSheetManager.busy(this, promise);
    },

    onRouteLeave() {
        super.onRouteLeave();

        BottomSheetManager.relase(this);
    },

    __proto__: Page,
};

createView((page) => {
    return {
        get title() {
            return page.content?.name;
        },

        get content() {
            if (!page.content) {
                return '';
            }

            return Iterator.new(page.content.sections)
                .flatMap(section => section.blocks)
                .flatMap(block => block.slots)
                .map(slot => slot.data?.content)
                .intoArray()
                .join('').replace(/[ \n]+/g, ' ');
        },

        __proto__: defaultViewFactory(page),
    };
}, ContentPage);
