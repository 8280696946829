import { Entity, pStore } from './Entity';
import { ProductMedia } from './ProductMedia';
import { CalculatedPrice } from './CalculatedPrice';
import { EntityCollection } from './EntityCollection';

export const CartLineItem = {

    /**
     * @type {string}
     */
    get label() {
        return this.value?.label;
    },

    /**
     * @type {string}
     */
    get referencedId() {
        return this.value?.referencedId;
    },

    /**
     * @type {number}
     */
    get quantity() {
        return this.value?.quantity;
    },

    /**
     * @type {string}
     */
    get type() {
        return this.value?.type;
    },

    /**
     * @type {ProductMedia}
     */
    get cover() {
        return this.value?.cover;
    },

    /**
     * @type {CalculatedPrice}
     */
    get price() {
        return this.value?.price;
    },

    /**
     * @type {boolean}
     */
    get removable() {
        return this.value?.removable;
    },

    /**
     * @type {boolean}
     */
    get stackable() {
        return this.value?.stackable;
    },

    /**
     * @type {boolean}
     */
    get modified() {
        return this.value?.modified;
    },

    /**
     * @return {EntityCollection}
     */
    get children() {
        return this[pStore].lazyCollection(this.value?.children);
    },

    get quantityInformation() {
        return this.value?.quantityInformation;
    },

    fill(value) {
        value.quantityInformation = this[pStore].createOrUpdate(value.quantityInformation);
        value.price = this[pStore].createOrUpdate(value.price);

        if (value.cover) {
            value.cover = this[pStore].createOrUpdate(value.cover);
        }

        return super.fill(value);
    },

    __proto__: Entity,
};

export default CartLineItem;
