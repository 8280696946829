import EventTarget from 'application-frame/core/EventTarget';

import TypeMap from './TypeMap';
import EntityCollection from './EntityCollection';

const pStore = Symbol('EntityStore.store');

const resolveId = (idOrEntity) => (idOrEntity?.id ?? (idOrEntity?.token && idOrEntity?.apiAlias)) ?? idOrEntity;

export const EntityStore = {
    [pStore]: null,

    Events: {
        Upload: Symbol('EntityStore.Events.Upload'),
        Refresh: Symbol('EntityStore.Events.Refresh'),
    },

    has(idOrEntity) {
        const id = resolveId(idOrEntity);

        return this[pStore].has(id);
    },

    get(idOrEntity) {
        const id = resolveId(idOrEntity);

        return this[pStore].get(id);
    },

    set(entity) {
        return this[pStore].set(entity.id, entity);
    },

    entityChanged(entity) {
        const type = Object.getPrototypeOf(entity);
        const id = entity.id;

        this.emit(this.Events.Upload, { type, id, entity });
    },

    refreshEntity(entity, params) {
        const type = Object.getPrototypeOf(entity);
        const id = entity.id;
        const parameter = JSON.parse(JSON.stringify(params));

        this.emit(this.Events.Refresh, { type, id, parameter, entity });
    },

    createOrUpdate(data) {
        if (!data.apiAlias) {
            throw new Error('provided object can not be instantiated as a shopware entity');
        }

        const dataType = TypeMap[data.apiAlias];

        if (!dataType) {
            throw new Error(`unknown entity type: ${data.apiAlias}`);
        }

        if ((data.id ?? data.token) !== null && this.has(data)) {
            const existingInstance = this.get(data);

            existingInstance.fill(data);

            return existingInstance;
        }

        return dataType.new(data, this);
    },

    collection(list) {
        return EntityCollection.new(list, this);
    },

    lazyCollection(list) {
        return EntityCollection.newLazy(list, this);
    },

    new() {
        return ({ [pStore]: new Map(), __proto__: this }).constructor();
    },

    __proto__: EventTarget,
};

export default EntityStore;
