import { Entity, pStore } from './Entity';

export const ProductMedia = {
    get productId() {
        return this.value?.productId;
    },

    get mediaId() {
        return this.value?.mediaId;
    },

    get position() {
        return this.value?.position;
    },

    get media() {
        return this.value?.media;
    },

    fill(value) {
        value.media = this[pStore].createOrUpdate(value.media);

        return super.fill(value);
    },

    __proto__: Entity,
};

export default ProductMedia;
