import { RenderEngine } from 'application-frame/rendering';

export const Animations = {
    enter(element) {
        return new Promise(resolve => {
            RenderEngine.scheduleRenderTask(() => {
                element.classList.add('enter');

                element.shadowRoot.querySelector('.sheet')
                    .addEventListener('transitionend', () => {
                        element.classList.remove('enter');
                        resolve();
                    }, { once: true });
            });
        });
    },

    leave(element) {
        return new Promise(resolve => {
            RenderEngine.scheduleRenderTask(() => {
                element.classList.add('leave');

                element.shadowRoot.querySelector('.sheet')
                    .addEventListener('transitionend', () => {
                        element.classList.remove('leave');
                        resolve();
                    }, { once: true });
            });
        });
    },
};

export default Animations;
