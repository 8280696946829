import async from 'application-frame/core/async';

import { Router } from '../lib/Router';
import { ProductListingManager } from './ProductListing';

const Callbacks = {
    onNavigation: Symbol('PersistentFiltersManager.Callbacks.onNavigation'),
    onUpdate: Symbol('PersistentFiltersManager.Callbacks.onUpdate'),
    onListingReady: Symbol('PersistentFiltersManager.Callbacks.onListingReady'),
};

export const PersistentFiltersManager = {
    Comparison: {
        propertyIds: 'equals',
    },

    filterState: null,
    lastUrl: null,
    updating: null,
    stateString: null,
    externalUrlParams: null,

    get listing() {
        return ProductListingManager.listing;
    },

    init() {
        this.filterState = new Map();
        this.externalUrlParams = new Map();

        ProductListingManager.ready.then(this[Callbacks.onListingReady].bind(this));

        window.addEventListener('hashchange', this[Callbacks.onNavigation].bind(this));
    },

    getFilter(type, key) {
        const values = this.filterState.get(type)?.get(key);

        return values ? Array.from(values) : [];
    },

    isFilterActive(type, key, value) {
        return this.getFilter(type, key).has(value);
    },

    addFilter(type, key, value) {
        if (!value) {
            throw new TypeError('filter value can not be empty!');
        }

        const filterType = this.filterState.get(type) ?? new Map();
        const filterGroup = filterType.get(key) ?? new Set();

        filterGroup.add(value);
        filterType.set(key, filterGroup);
        this.filterState.set(type, filterType);

        this.listing?.addPostFilter({ type: this.Comparison[type], field: type, value });
        this.storeState();
    },

    removeFilter(type, key, value) {
        const filterGroup = this.filterState.get(type)?.get(key);

        if (!filterGroup) {
            return;
        }

        const existingFilters = this.listing.postFilters.filter(filter =>
            filter.type === this.Comparison[type] &&
            filter.field === type &&
            filter.value === value
        );

        existingFilters.forEach(filter => this.listing?.removePostFilter(filter));
        filterGroup.delete(value);

        if (filterGroup.size === 0) {
            this.filterState.get(type).delete(key);
        }

        this.storeState();
    },

    buildQueryString() {
        const params = Array.from(this.filterState.entries())
            .flatMap(([type, list]) => {
                return Array.from(list.entries())
                    .map(([key, value]) => {
                        return [`${type}_${key}`, Array.from(value)];
                    });
            });

        return (new URLSearchParams(params)).toString();
    },

    parseCurrentUrl() {
        const currentPath = location.hash.split('/');

        currentPath[0] = 'fragment://';

        return new URL(currentPath.join('/'));
    },

    restoreFromUrl() {
        const url = this.parseCurrentUrl();
        const params = url.searchParams;

        this.filterState.clear();

        Array.from(params.entries()).forEach(([path, value]) => {
            const [type, key] = path.split('_');
            const values = value.split(',');

            if (!type || !key) {
                return this.externalUrlParams.set(path, value);
            }

            values.forEach(value => {
                if (!value) {
                    return;
                }

                this.addFilter(type, key, value);
            });
        });
    },

    storeState() {
        if (this.updating) {
            return;
        }

        this.updating = async(this[Callbacks.onUpdate].bind(this));
    },

    [Callbacks.onNavigation]() {
        const url = this.parseCurrentUrl();

        if (url.search) {
            if (url.search !== this.stateString) {
                return this.restoreFromUrl();
            }

            return;
        }

        this.storeState();
    },

    [Callbacks.onUpdate]() {
        const queryString = this.buildQueryString();
        const changed = (`?${queryString}` !== this.stateString);

        this.updating = null;

        if (!queryString && !this.stateString) {
            return;
        }

        this.stateString = queryString ? `?${queryString}` : '';
        location.replace(`${Router.getCurrentPath().join('/')}${this.stateString}`);

        if (!changed) {
            return;
        }

        ProductListingManager.refresh();
    },

    [Callbacks.onListingReady]() {
        return this.restoreFromUrl();
    }
};

export default PersistentFiltersManager;
