import { Entity, pStore } from './Entity';

export const CmsPageSection = {
    get type() {
        return this.value?.type;
    },

    get sizingMode() {
        return this.value?.sizingMode;
    },

    get mobileBehavior() {
        return this.value?.mobileBehavior;
    },

    get blocks() {
        return this[pStore].lazyCollection(this.value?.blocks);
    },


    __proto__: Entity,
};

export default CmsPageSection;
