import DataStorage from 'application-frame/core/DataStorage';
import EntityStore from './EntityStore';

const isEntity = Symbol('Entity.isEntity');

export const pStore = Symbol('Entity.entityStore');

export const Entity = {
    isEntity,

    /**
     * @type {EntityStore}
     */
    [pStore]: null,
    [isEntity]: true,

    get id() { return this.value?.id; },

    new(value, entityStore) {
        const instance = ({ [pStore]: entityStore, __proto__: this }).constructor();

        if (value) {
            instance.fill(value);
        }

        if (instance.id) {
            entityStore.set(instance);
        }

        return instance;
    },

    __proto__: DataStorage,
};

export default Entity;
