import { Page, defaultViewFactory, createView } from '../prototypes/Page';
import { BottomSheetManager } from '../managers/BottomSheet';
import { CartManager } from '../managers/Cart';
import { CurrencyManager } from '../managers/Currency';
import { EventSubscriberFeature } from '../lib/EventSubscriberFeature';
import { CartPrice } from '../lib/shopware/CartPrice';
import { CartTaxCalculated } from '../lib/shopware/CartTaxCalculated';
import { Shopware } from '../managers/Shopware';
import { DialogManager } from '../managers/Dialog';

export const CartPage = {
    name: 'CartPage',
    path: '/cart',
    template: 'page-cart',
    context: null,

    init(template) {
        EventSubscriberFeature(CartManager, this);
        Shopware.context.then(context => this.context = context);

        return super.init(template);
    },

    onRouteEnter() {
        super.onRouteEnter();

        BottomSheetManager.request(this);
    },

    onRouteLeave() {
        super.onRouteLeave();

        BottomSheetManager.relase(this);
    },

    [CartManager.Events.Update]() {
        if (!this.active) {
            return;
        }

        this.scope.update();
    },

    __proto__: Page,
};

createView((page) => {
    return {
        strings: {
            continue: 'checkout.proceedToCheckout',
            taxLabel: 'hotWaiter.checkout.cart.taxLabel',
            grossLabel: 'hotWaiter.checkout.cart.grossLabel',
            netLabel: 'hotWaiter.checkout.cart.netLabel',
            taxValueLabel: 'hotWaiter.checkout.cart.taxValueLabel',
            totalLabel: 'checkout.summaryTotalPrice',
            completeOrder: 'checkout.confirmSubmit',
            saveItem: 'hotWaiter.checkout.saveQuantity',
            deleteItem: 'hotWaiter.checkout.deleteItem',
        },

        get items() {
            if (!CartManager.lineItems) {
                return [];
            }

            return Array.from(CartManager.lineItems);
        },

        get cartPrice() {
            return CartManager.price;
        },

        get linkToNextStep() {
            if (!page.context?.customer) {
                return '#!/sign-in';
            }

            if (!page.context?.paymentMethod) {
                return '#!/payment';
            }

            return '#!/complete';
        },

        get isCheckPossible() {
            return CartManager.productCount > 0;
        },

        get continueCheckoutLabel() {
            return this.hasCustomer ? this.strings.completeOrder : this.strings.continue;
        },

        get hasCustomer() {
            return !!page.context?.customer;
        },

        get paymentMethod() {
            return page.context?.paymentMethod?.name;
        },

        /**
         * @param  {number} value
         *
         * @return {string}
         */
        currency(value) {
            return CurrencyManager.format(value);
        },

        /**
         * @param  {({ '[Symbol.iterator]': Function }|null)} value
         *
         * @return {Array}
         */
        asArray(value) {
            if (!value) {
                return [];
            }

            return Array.from(value);
        },

        /**
         * @param  {CartTaxCalculated} tax
         *
         * @return {string}
         */
        netPrice(tax) {
            const isNet = this.cartPrice.taxStatus === CartPrice.TaxStatus.Net;
            const value = isNet ? tax.price : tax.price - tax.tax;

            return this.currency(value);
        },

        /**
         * @param  {CartTaxCalculated} tax
         *
         * @return {string}
         */
        grossPrice(tax) {
            const isGross = this.cartPrice.taxStatus === CartPrice.TaxStatus.Gross;
            const value = isGross ? tax.price : tax.price + tax.tax;

            return this.currency(value);
        },

        onSelectItem(event, scope) {
            const { maxPurchase = 1, minPurchase = 1 } = scope.item.quantityInformation;

            DialogManager.show({
                template: '#fragment-change-quantity',
                title: scope.item.label,
                data: { min: minPurchase, max: maxPurchase, value: scope.item.quantity },
                actions: [
                    { name: 'delete', label: this.l10n(this.strings.deleteItem) },
                    { name: 'save', label: this.l10n(this.strings.saveItem) },
                ]
            }).then(event => {
                if (event.action === 'delete') {
                    return CartManager.remove(scope.item);
                }

                if (event.action !== 'save') {
                    return;
                }

                const quantityChange = event.data.value - scope.item.quantity;

                return CartManager.update(scope.item, quantityChange);
            });
        },

        __proto__: defaultViewFactory(page),
    };
}, CartPage);
