import { Entity, pStore } from './Entity';

export const CmsPageSlot = {
    get id() {
        return this.value?.id;
    },

    get data() {
        return this.value?.data;
    },

    fill(value) {
        if (value.data) {
            const wrapper = Array.isArray(value.data) ? 'lazyCollection' : 'createOrUpdate';

            value.data = this[pStore][wrapper](value.data);
        }

        super.fill(value);
    },

    __proto__: Entity,
};

export default CmsPageSlot;
