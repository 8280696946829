import { Component, createView, defaultViewFactory } from '../prototypes/Component';
import { RenderEngine } from 'application-frame/rendering';
import EventTarget from 'application-frame/core/EventTarget';
import DataBinding from '@af-modules/databinding';

const pOpen = Symbol('Dialog.open');
const pTitle = Symbol('Dialog.title');
const pActions = Symbol('Dialog.actions');
const pInnerScope = Symbol('Dialog.innerScope');

export const Events = {
    Close: Symbol('DialogComponent.Events.Close'),

    __proto__: EventTarget,
};

export const Dialog = {
    Events,

    [pOpen]: false,
    [pTitle]: null,
    [pActions]: [],
    [pInnerScope]: null,

    name: 'Dialog',
    template: 'component-dialog',

    get open() {
        return this[pOpen];
    },

    set open(value) {
        this[pOpen] = !!value;

        this.scope.update();
    },

    setContent({ template, title = null, data = {}, actions = [] } = {}) {
        const target = document.querySelector(`#${this.id} .content`);

        if (!target) {
            RenderEngine.schedulePostRenderTask(() => this.setContent({ template, data, actions }));

            return;
        }

        this[pInnerScope]?.__destroy__();

        Array.from(target.children).forEach(child => {
            target.removeChild(child);
        });

        const { node, scope } = DataBinding.createTemplateInstance({ template, scope: data });

        this[pTitle] = title;
        this[pActions] = actions;
        this[pInnerScope] = scope;
        target.appendChild(node);
    },

    new() {
        const instance = super.new();

        instance.Events = Object.create(this.Events).constructor();

        return instance;
    },

    __proto__: Component,
};

createView((component) => {
    return {
        get title() {
            return component[pTitle];
        },

        get actions() {
            return component[pActions];
        },

        get id() {
            return component.id;
        },

        get open() {
            return component.open;
        },

        set open(value) {
            component.open = value;
        },

        onClosed(event) {
            component.Events.emit(component.Events.Close, event);
        },

        __proto__: defaultViewFactory(component),
    };
}, Dialog);

export default Dialog;
