import EventTarget from 'application-frame/core/EventTarget';

import Shopware from './Shopware';

const Callbacks = {
    onShopwareContextReady: Symbol('ProductListingManager.Callbacks.onShopwareContextReady'),
};

const LoadReason = {
    Filter: Symbol('ProductListingManager.LoadReason.Filter'),
    Listing: Symbol('ProductListingManager.LoadReason.Listing'),
};

const pReady = Symbol('ProductListingManager.ready');

export const ProductListingManager = {
    [pReady]: null,
    listing: null,
    ready: null,

    LoadReason,

    init() {
        this.constructor();
        this.ready = new Promise((ready) => this[pReady] = ready);

        Shopware.context.then(this[Callbacks.onShopwareContextReady].bind(this));
    },

    getLimit(reason) {
        if (reason === LoadReason.Filter) {
            return 1;
        }

        if (reason === LoadReason.Listing) {
            return 500;
        }

        return 0;
    },

    initialLoad(reason) {
        if (!reason) {
            throw new Error('no initial load reason specified for product listing');
        }

        if (!this.listing) {
            throw new Error('listing is not yet ready, await ProductListingManager.ready');
        }

        const limit = this.getLimit(reason);

        if (!this.listing.isEmpty && limit < this.listing.limit) {
            return;
        }

        return this.listing.refresh({ limit });
    },

    refresh() {
        if (this.listing?.isEmpty ?? true) {
            return;
        }

        this.listing.refresh();
    },

    [Callbacks.onShopwareContextReady](context) {
        const { salesChannel: { navigationCategory } } = context;

        this.listing = Shopware.listing(navigationCategory.id);
        this.listing.addFilter({ type: 'equals', field: 'active', value: true });

        this[pReady](this.listing);
    },

    __proto__: EventTarget,
};

export default ProductListingManager;
