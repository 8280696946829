import { Page, defaultViewFactory, createView } from '../prototypes/Page';
import { BottomSheetManager } from '../managers/BottomSheet';
import { Router } from '../lib/Router';
import { Shopware } from '../managers/Shopware';

export const FoodLabelsPage = {
    name: 'FoodLabelsPage',
    path: '/food-labels/{productId}',
    template: 'page-food-labels',
    productId: null,
    product: null,

    init(template) {
        return super.init(template);
    },

    onRouteEnter(path, params) {
        Object.assign(this, params);
        super.onRouteEnter();

        if (!this.productId) {
            return Router.up();
        }

        const promise = Shopware.product(this.productId)
            .then(product => {
                this.product = product;
                this.scope.update();
            });

        BottomSheetManager.request(this);
        BottomSheetManager.busy(this, promise);
    },

    onRouteLeave() {
        super.onRouteLeave();

        BottomSheetManager.relase(this);
    },

    __proto__: Page,
};

createView((page) => {
    return {
        get title() {
            return page.product.name;
        },

        get content() {
            return page.product.customFields.hot_food_fields_labels.replace(/[ \n]+/g, ' ');
        },

        __proto__: defaultViewFactory(page),
    };
}, FoodLabelsPage);
