import { HTMLElement } from 'application-frame/core/nativePrototype';

import defineCustomElement from '../../lib/defineCustomElement';
import template from './template';

const HotFooterMeta = {
    name: 'hot-footer',
    template,
    get object() { return HotFooter; },
};

const meta = HotFooterMeta;

const HotFooter = {
    constructor: function HotFooter() {
        const instance = HTMLElement.constructor.apply(this);

        instance._create();

        return instance;
    },

    _create() {
        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(document.importNode(template.content, true));
    },

    connectedCallback() {
        this.setAttribute('resolved', '');
    },

    __proto__: HTMLElement,
};

defineCustomElement(meta);

export { HotFooter, HotFooterMeta };
