import CmsPage from './CmsPage';
import CmsPageSection from './CmsPageSection';
import CmsPageBlock from './CmsPageBlock';
import CmsPageSlot from './CmsPageSlot';
import CmsPageText from './CmsPageText';
import CmsPageImage from './CmsPageImage';
import CmsPageProductBox from './CmsPageProductBox';
import Category from './Category';
import Cart from './Cart';
import Context from './Context';
import SalesChannel from './SalesChannel';
import CalculatedPrice from './CalculatedPrice';
import Product from './Product';
import Currency from './Currency';
import CustomerGroup from './CustomerGroup';
import PaymentMethod from './PaymentMethod';
import ShippingMethod from './ShippingMethod';
import ShippingLocation from './ShippingLocation';
import Country from './Country';
import EntitiesAggregation from './EntitiesAggregation';
import ProductGroup from './ProductGroup';
import PropertyGroupOption from './PropertyGroupOption';
import Language from './Language';
import Locale from './Locale';
import ProductMedia from './ProductMedia';
import Media from './Media';
import MediaThumbnail from './MediaThumbnail';
import CartLineItem from './CartLineItem';
import CartPrice from './CartPrice';
import CartTaxCalculated from './CartTaxCalculated';
import Customer from './Customer';
import Salutation from './Salutation';
import Order from './Order';
import StateMachineState from './StateMachineState';
import CartQuantityInformation from './CartQuantityInformation';

export const TypeMap = {
    cms_page: CmsPage,
    cms_section: CmsPageSection,
    cms_block: CmsPageBlock,
    cms_slot: CmsPageSlot,
    cms_text: CmsPageText,
    cms_image: CmsPageImage,
    cms_product_box: CmsPageProductBox,
    category: Category,
    cart: Cart,
    sales_channel_context: Context,
    sales_channel: SalesChannel,
    currency: Currency,
    product: Product,
    calculated_price: CalculatedPrice,
    customer_group: CustomerGroup,
    shipping_method: ShippingMethod,
    payment_method: PaymentMethod,
    shipping_location: ShippingLocation,
    country: Country,
    properties_aggregation: EntitiesAggregation,
    product_group: ProductGroup,
    property_group: ProductGroup,
    property_group_option: PropertyGroupOption,
    language: Language,
    locale: Locale,
    product_media: ProductMedia,
    media: Media,
    media_thumbnail: MediaThumbnail,
    cart_line_item: CartLineItem,
    cart_price: CartPrice,
    cart_tax_calculated: CartTaxCalculated,
    customer: Customer,
    salutation: Salutation,
    order: Order,
    order_customer: Customer,
    state_machine_state: StateMachineState,
    cart_quantity_information: CartQuantityInformation,
};

export default TypeMap;
