import { Entity, pStore } from './Entity';

export const PaymentMethod = {

    get name() {
        return this.value?.translated?.name ?? this.value?.name;
    },

    get description() {
        return this.value?.translated?.description ?? this.value?.description;
    },

    get active() {
        return this.value?.active;
    },

    get media() {
        return this[pStore].get(this.value?.mediaId);
    },

    fill(value) {
        if (this.value?.media) {
            this[pStore].createOrUpdate(this.value?.media);
        }

        return super.fill(value);
    },

    __proto__: Entity,
};

export default PaymentMethod;
