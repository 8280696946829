import { Entity } from './Entity';

export const Product = {

    get name() {
        return this.value?.translated?.name ?? this.value?.name;
    },

    get displayGross() {
        return this.value?.displayGross;
    },

    __proto__: Entity,
};

export default Product;
