import { HTMLElement } from 'application-frame/core/nativePrototype';
import { DataBinding } from '@af-modules/databinding';

import defineCustomElement from '../../lib/defineCustomElement';
import template from './template';

const HotCartItemMeta = {
    name: 'hot-cart-item',
    template,
    get object() { return HotCartItem; },
};

const meta = HotCartItemMeta;
const pScope = Symbol('HotInfoButton.scope');
const pView = Symbol('HotInfoButton.view');

const createView = function() {
    return {};
};

const HotCartItem = {
    constructor: function HotHeader() {
        const instance = HTMLElement.constructor.apply(this);

        instance._create();

        return instance;
    },

    _create() {
        const view = createView(this);
        const { scope, node } = DataBinding.createTemplateInstance({ template, scope: view });

        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(node);

        this[pScope] = scope;
        this[pView] = view;

        DataBinding.attachBindings(this[pScope], this, [
            { selector: 'root', name: 'bind-ripple', value: '.ripple' }
        ]);
    },

    connectedCallback() {
        this.setAttribute('resolved', '');
    },

    __proto__: HTMLElement,
};

defineCustomElement(meta);

export { HotCartItem, HotCartItemMeta };
