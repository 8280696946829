import { HTMLElement } from 'application-frame/core/nativePrototype';

import defineCustomElement from '../../lib/defineCustomElement';
import template from './template';

const HotAccordionGroupMeta = {
    name: 'hot-accordion-group',
    template,
    get object() { return HotAccordionGroup; },
};

const meta = HotAccordionGroupMeta;

const Callbacks = {
    onChildOpen: Symbol('HotAccordionGroup.Callbacks.onChildOpen'),
};

const HotAccordionGroup = {
    constructor: function HotAccordionGroup() {
        const instance = HTMLElement.constructor.apply(this);

        instance._create();

        return instance;
    },


    _create() {
        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(document.importNode(template.content, true));
        this.addEventListener('open', this[Callbacks.onChildOpen].bind(this));
    },

    connectedCallback() {
        this.setAttribute('resolved', '');
    },

    [Callbacks.onChildOpen](event) {
        const children = Array.from(this.children);

        if (!children.includes(event.target)) {
            return;
        }

        children.forEach(child => {
            if (child === event.target) {
                return;
            }

            child.open = false;
        });
    },

    __proto__: HTMLElement,
};

defineCustomElement(meta);

export { HotAccordionGroup, HotAccordionGroupMeta };
