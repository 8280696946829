import { HTMLElement } from 'application-frame/core/nativePrototype';
import DataBinding from '@af-modules/databinding';

import defineCustomElement from '../../lib/defineCustomElement';
import template from './template';
import { Animations } from './animations';

const HotBottomSheetMeta = {
    name: 'hot-bottom-sheet',
    template,
    attributes: ['open'],
    get object() { return HotBottomSheet; },
};

const meta = HotBottomSheetMeta;
const pScope = Symbol('HotButton.scope');
const pView = Symbol('HotButton.view');
const pWasOpen = Symbol('HotButton.wasOpen');

const States = {
    Open: Symbol('States.Open'),
    Return: Symbol('States.Return'),
    Collapse: Symbol('States.Collapse'),
    Scroll: Symbol('States.Scroll'),
    Grabbed: Symbol('States.Grabbed'),
};

const GrabEvents = {
    Move: ('ontouchmove' in window) ? 'touchmove' : 'pointermove',
    End: ('ontouchend' in window) ? 'touchend' : 'pointerup',
};

const createView = function(element) {
    return {
        offset: 0,
        grabStartPosition: 0,
        grabListeners: null,
        state: States.Open,
        isScrolled: false,
        animations: Animations,

        get shouldReturn() {
            return this.state === States.Return;
        },

        get shouldCollapse() {
            return this.state === States.Collapse;
        },

        get isOpen() { return element.open; },

        get shouldEnter() {
            return element.open;
        },

        get shouldLeave() {
            if (!element[pWasOpen]) {
                return null;
            }

            return !element.open;
        },

        get sheetStyleProperties() {
            return `--vertical-offset: ${this.offset}; --full-height: ${this.fullHeight};`;
        },

        get isGrabbed() {
            return this.state === States.Grabbed ||
                    this.state === States.Return ||
                    this.state === States.Collapse;
        },

        onGrab(event) {

            // Don't grab the sheet a second time, if we already grabbed it
            if (this.state === States.Grabbed) {
                return;
            }

            const moveListener = this.onMove.bind(this);
            const releaseListener = this.onRelease.bind(this);

            this.grabListeners = [moveListener, releaseListener];
            this.grabStartPosition = event.screenY;
            this.state = States.Open;
            this.offset = 0;

            window.addEventListener(GrabEvents.Move, moveListener);
            window.addEventListener(GrabEvents.End, releaseListener);
        },

        onMove(event) {

            // Ignore this event if the user is scrolling inside the sheet
            if (this.state === States.Scroll) {
                return;
            }

            const currentPosition = event.changedTouches?.[0]?.screenY ?? event.screenY;

            this.offset = Math.max(currentPosition - this.grabStartPosition, 0);

            // If the state is still open, then we have to decide whether the user
            // is scrolling or grabbing the sheet
            if (this.state === States.Open) {

                // If the content area is scrolled or the pointer didn't
                // move downwards, then we assume the scrolling state
                if (this.isScrolled || this.offset === 0) {
                    this.state = States.Scroll;
                    this.clearGrablisteners();
                    element[pScope].update();

                    return;
                }

                // if the pointer moved downwards we assume the grabbed state
                if (this.offset > 0) {
                    this.state = States.Grabbed;
                }
            }

            // if the user drags past a 0 offset (sheet is fully expanded),
            // then we move the startPosition to the currentPosition, so the user
            // can imediately start swiping down again.
            if (this.offset === 0) {
                this.grabStartPosition = currentPosition;
            }

            element[pScope].update();
        },

        onRelease(event) {
            if (!(event.touches?.length < 1) && !event.isPrimary) {
                return;
            }

            this.clearGrablisteners();
            this.fullHeight = element.shadowRoot.querySelector('.sheet').offsetHeight;

            // depending on how much was swiped down (> 15%), we choose to either
            // collapse the sheet or return to fully expanded
            this.state = ((this.offset / this.fullHeight) < 0.15) ? States.Return : States.Collapse;

            if (this.state === States.Collapse) {
                element.open = false;
            }

            element[pScope].update();
        },

        clearGrablisteners() {
            if (!this.grabListeners) {
                return;
            }

            const [moveListener, releaseListener] = this.grabListeners;

            window.removeEventListener(GrabEvents.Move, moveListener);
            window.removeEventListener(GrabEvents.End, releaseListener);

            this.grabListeners = null;
        },

        onBackdrop() {
            element.open = false;
            this.clearGrablisteners();
        },

        onOpen() {
            this.offset = 0;
            this.state = States.Open;
        },

        onScroll(event) {
            this.isScrolled = event.target.scrollTop > 0;
        },
    };
};

const HotBottomSheet = {
    get open() {
        return this.hasAttribute('open');
    },

    set open(value) {
        value ? this.setAttribute('open', '') : this.removeAttribute('open');
    },

    get busy() {
        return this.hasAttribute('busy');
    },

    set busy(value) {
        value ? this.setAttribute('busy', '') : this.removeAttribute('busy');
    },

    constructor: function HotBottomSheet() {
        const instance = HTMLElement.constructor.apply(this);

        instance._create();

        return instance;
    },

    _create() {
        const view = createView(this);
        const { scope, node } = DataBinding.createTemplateInstance({ template, scope: view });

        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(node);

        this[pScope] = scope;
        this[pView] = view;

        DataBinding.attachBindings(this[pScope], this, [
            { selector: 'root', name: 'bind-animation', value: '{ view.shouldEnter: view.animations.enter, view.shouldLeave: view.animations.leave }'},
            { selector: 'root', name: 'bind-event', parameter: 'open', value: 'view.onOpen' },
        ]);
    },

    connectedCallback() {
        this.setAttribute('resolved', '');
    },

    attributeChangedCallback(name) {
        if (name === 'open') {
            const eventName = this.hasAttribute('open') ? 'open' : 'close';

            this[pWasOpen] = true;
            this.classList.add('animated');
            this.dispatchEvent(new CustomEvent(eventName, { bubbles: true }));
            this.dispatchEvent(new CustomEvent('statechange', { bubbles: true }));
        }

        if (name === 'busy') {
            const event = new CustomEvent('availability', {
                bubbles: true,
                detail: {
                    busy: this.hasAttribute('busy ')
                }
            });

            this.dispatchEvent(event);
        }

        this[pScope].update();
    },

    __proto__: HTMLElement,
};

defineCustomElement(meta);

export { HotBottomSheet, HotBottomSheetMeta };
