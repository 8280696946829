import { Entity } from './Entity';

export const Locale = {
    get name() {
        return this.value?.translated?.name ?? this.value?.name;
    },

    get territory() {
        return this.value?.translated?.territory ?? this.value?.territory;
    },

    get code() {
        return this.value?.code;
    },

    __proto__: Entity,
};

export default Locale;
