import { Router as AfRouter } from '@af-modules/router';

export const Router = {

    /**
     *
     * @return {string[]} - array of all parts of the current route
     */
    getCurrentPath() {
        const path = (window.location.hash === '') ? ('#!/').split('/') : window.location.hash.split('/');
        const last = path.length - 1;
        const [lastPart] = path[last].split('?');

        path[last] = lastPart;

        if (path.length === 2 && path[1] === '') {
            path[1] = 'root';
        }

        return path;
    },


    backupPath(currentPath) {
        if (currentPath[0] === 'root') {
            currentPath = [''];
        }

        super.backupPath(currentPath);
    },

    __proto__: AfRouter,
};

export default Router;
