import { Entity, pStore } from './Entity';

export const Media = {
    get mimeType() {
        return this.value?.mimeType;
    },

    get extension() {
        return this.value?.fileExtension;
    },

    get size() {
        return this.value?.fileSize;
    },

    get title() {
        return this.value?.translated?.title ?? this.value?.title;
    },

    get alt() {
        return this.value?.translated?.alt ?? this.value?.alt;
    },

    get name() {
        return this.value?.fileName;
    },

    get url() {
        return this.value?.url;
    },

    get thumbnails() {
        return this[pStore].lazyCollection(this.value?.thumbnails);
    },

    fill(value) {
        return super.fill(value);
    },

    __proto__: Entity,
};

export default Media;
