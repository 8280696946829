import { Entity, pStore } from './Entity';

export const ShippingLocation = {

    get min() {
        return this.value?.country;
    },

    get state() {
        return this.value?.state;
    },

    get address() {
        return this.value?.address;
    },

    fill(value) {
        if (value.country) {
            this[pStore].createOrUpdate(value.country);
        }

        if (value.state) {
            this[pStore].createOrUpdate(value.state);
        }

        if (value.address) {
            this[pStore].createOrUpdate(value.address);
        }

        return super.fill(value);
    },

    __proto__: Entity,
};

export default ShippingLocation;
