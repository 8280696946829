import { Entity } from './Entity';

export const CartTaxCalculated = {

    /**
     * @type {number}
     */
    get tax() {
        return this.value?.tax;
    },

    /**
     * @type {number}
     */
    get price() {
        return this.value?.price;
    },

    /**
     * @type {number}
     */
    get rate() {
        return this.value?.taxRate;
    },

    __proto__: Entity,
};

export default CartTaxCalculated;
