import NetworkRequest from 'application-frame/core/NetworkRequest';

const objectToQueryString = function(data) {
    const expanded = [];
    const keyValuePairs = Object.entries(data)
        .map(([key, value]) => transformType(key, value, expanded))
        .concat(expanded)
        .filter(([, value]) => value !== null && value !== undefined);

    const searchParams = new URLSearchParams(keyValuePairs);

    return searchParams.toString();
};

const transformType = function (key, value, expanded = []) {
    if (typeof value === 'boolean') {
        value = value ? 1 : 0;
    }

    if (typeof value === 'object') {
        transformObject(key, value, expanded);

        value = null;
    }

    return [key, value];
};

const transformObject = function(key, object, expanded = []) {
    Object.entries(object).forEach(([childKey, childValue]) => {
        const transformedKey = `${key}[${childKey}]`;
        const transformedPair = transformType(transformedKey, childValue, expanded);

        expanded.push(transformedPair);
    });
};

export const BaseApi = {
    Store: 'store-api',
    SalesChannel: 'sales-channel-api',
};

export const request = function(path, { data, api, method = 'GET', base = BaseApi.Store } = {}) {
    const useBody = method.toUpperCase() !== 'GET';
    const queryString = (!useBody && data) ? `?${objectToQueryString(data)}` : '';
    const url = `${api.host}/${base}/v${api.version}${path}${queryString}`;
    const request = Object.create(NetworkRequest).constructor(url, { method, type: 'json' });

    request.body(useBody ? data : null)
        .setHeader('sw-access-key', api.key);

    if (api.languageId) {
        request.setHeader('sw-language-id', api.languageId);
    }

    if (api.contextToken) {
        request.setHeader('sw-context-token', api.contextToken);
    }

    request.onReady(xhr => {
        const contextIds = xhr.getResponseHeader('sw-context-token').split(',').map(id => id.trim());

        if (!contextIds) {
            return;
        }

        if (contextIds.length > 2) {
            throw new Error('received more than two context ids, unable to handle the situation!');
        }

        const newToken = contextIds.filter(id => id !== api.contextToken)[0];

        if (!newToken) {
            return;
        }

        api.contextToken = newToken;
    });

    return request.send();
};

export default request;
