import { Entity, pStore } from './Entity';

export const CmsPageBlock = {
    get type() {
        return this.value?.type;
    },

    get slots() {
        return this[pStore].lazyCollection(this.value?.slots);
    },


    __proto__: Entity,
};

export default CmsPageBlock;
