import { ComponentCallbacks } from '../prototypes/Component';
import { Page, defaultViewFactory, createView } from '../prototypes/Page';
import { BottomSheetManager } from '../managers/BottomSheet';
import { Shopware } from '../managers/Shopware';
import { Context } from '../lib/shopware/Context';
import { EntityCollection } from '../lib/shopware/EntityCollection';
import { PaymentMethod } from '../lib/shopware/PaymentMethod';
import { Iterator } from '../lib/Iterator';
import { Router } from '../lib/Router';

export const PaymentPage = {
    name: 'PaymentPage',
    path: '/payment',
    template: 'page-payment',

    /** @type {Promise[]} */
    dependencies: null,

    get localizedDependencies() {
        return [
            Shopware.availablePaymentMethods().then(methods => this.paymentMethods = methods)
        ];
    },

    /** @type {Context} */
    context: null,

    /** @type {EntityCollection.<PaymentMethod>} */
    paymentMethods: null,

    strings: {
        selectPayment: 'checkout.confirmChoosePayment',
    },

    init(template) {
        super.init(template);

        this.dependencies = [
            Shopware.context.then(context => this.context = context),
            ...this.localizedDependencies,
        ];
    },

    onRouteEnter() {
        super.onRouteEnter();

        const dependencies = Promise.all(this.dependencies).then(this.scope.update);

        BottomSheetManager.request(this);
        BottomSheetManager.busy(this, dependencies);
    },

    onRouteLeave() {
        super.onRouteLeave();

        BottomSheetManager.relase(this);
    },

    [ComponentCallbacks.onL10nChanged](event) {
        super[ComponentCallbacks.onL10nChanged](event);

        if (!event.old) {
            return;
        }

        Promise.all(this.localizedDependencies).then(this.scope.update);
    },

    __proto__: Page,
};

createView((page) => {
    return {
        localPaymentMethodId: null,

        get paymentMethodList() {
            if (!page.paymentMethods) {
                return [];
            }

            return Iterator.new(page.paymentMethods).intoArray();
        },

        get paymentMethodId() {
            return this.localPaymentMethodId ?? page.context?.paymentMethod.id;
        },

        set paymentMethodId(value) {
            this.localPaymentMethodId = value;
        },

        getPaymentMethod(paymentId) {
            if (!page.paymentMethods) {
                return null;
            }

            return Iterator.new(page.paymentMethods)
                .find(method => method.id === paymentId);
        },

        onSelectOption(event, scope) {
            this.paymentMethodId = scope.paymentMethod.id;
            page.scope.update();
        },

        onSetPaymentMethod() {
            page.context.modify({
                paymentMethodId: this.localPaymentMethodId
            });

            const task = page.context.whenNext
                .then(() => {
                    Router.switchTo('/cart');
                });

            BottomSheetManager.busy(page, task);
        },

        __proto__: defaultViewFactory(page),
    };
}, PaymentPage);
