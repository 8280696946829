import ShopwareApi from '../lib/shopware/Api';
import Context from '../lib/shopware/Context';
import Cart from '../lib/shopware/Cart';
import { LocalPreferencesManager, Preferences } from './LocalPreferences';
import { Config } from './Config';
import { SubscriberFeature } from 'application-frame/core/features/SubscriberFeature';

const pContext = Symbol('Shopware.context');
const Networking = {
    Fresh: Symbol('Shopware.Networking.Fresh'),
    Lazy: Symbol('Shopware.Networking.Lazy'),
};

export const Shopware = {
    Navigations: ShopwareApi.Navigations,
    Networking,

    [pContext]: null,
    api: null,

    /**
     * @type {Promise.<Context>}
     */
    get context() {
        if (!this[pContext]) {
            this[pContext] = this.api.context();
        }

        return this[pContext];
    },

    navigation(...args) { return this.api.navigation(...args); },
    listing(categoryId) { return this.api.listing(categoryId); },

    /**
     * @return {Promise.<Cart>}
     */
    cart() {
        return this.context.then(() => this.api.cart());
    },

    product(id, options, networking = Networking.Lazy) {
        if (networking === Networking.Lazy && this.api.entityStore.has(id)) {
            return Promise.resolve(this.api.entityStore.get(id));
        }

        return this.context
            .then(context => this.api.product(context, id, options));
    },

    language(id, networking = Networking.Lazy) {
        if (networking === Networking.Lazy && this.api.entityStore.has(id)) {
            return Promise.resolve(this.api.entityStore.get(id));
        }

        return this.api.language(id);
    },

    languages(params) {
        return this.api.languages(params);
    },

    salutations() {
        return this.api.salutations();
    },

    productOptions(productId) {
        return this.api.productOptions(productId);
    },

    account(data) {
        return this.api.account(data);
    },

    availablePaymentMethods() {
        return this.api.availablePaymentMethods();
    },

    availableShippingMethods() {
        return this.api.availableShippingMethods();
    },

    order(id) {
        const request = this.api.order(id);

        request.then(() => {
            this.api.context();
            this.api.cart();
        });

        return request;
    },

    handlePayment(orderId, errorUrl, finishUrl) {
        return this.api.handlePayment(orderId, errorUrl, finishUrl);
    },

    cms(pageId) {
        return this.api.cms(pageId);
    },

    /**
     * @param  {Config} config
     */
    init(config) {
        const contextId = LocalPreferencesManager.getPreference(Preferences.contextId);

        this.api = ShopwareApi.new(config.shopware.host, config.shopware.apiKey);

        SubscriberFeature(this.api, this);

        if (contextId) {
            this.api.contextToken = contextId;
        }
    },

    [ShopwareApi.Events.ContextChanged]({ newToken }) {
        LocalPreferencesManager.setPreference(Preferences.contextId, newToken);

        if (!this[pContext]) {
            return;
        }

        this[pContext].then(context => {
            if (context.token === newToken) {
                return;
            }

            this[pContext] = this.api.context();
        });
    },
};

export default Shopware;
