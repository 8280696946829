import { Dialog, Events as DialogEvents } from '../components/Dialog';
import { getTemplate } from '../lib/getTemplate';

const pQueue = Symbol('DialogManager.queue');

export const DialogManager = {
    [pQueue]: null,

    component: null,

    init() {
        this[pQueue] = Promise.resolve();
        this.component = Dialog.new();
        this.component.init(getTemplate(Dialog.template));
    },

    show(config) {
        this[pQueue] = this[pQueue].then(() => new Promise((resolve) => {
            this.component.setContent(config);
            this.component.open = true;

            const callback = (event) => {
                resolve({ data: config.data, action: event.detail.action });

                this.component.Events.removeListener(DialogEvents.Close, callback);
            };

            this.component.Events.on(DialogEvents.Close, callback);
        }));

        return this[pQueue];
    }
};

export default DialogManager;
