import './bindings/RippleBinding';
import './bindings/ValidityBinding';

import '@material/mwc-ripple';
import '@material/mwc-circular-progress';
import '@material/mwc-dialog';
import './elements/hot-header';
import './elements/hot-icon';
import './elements/hot-button';
import './elements/hot-icon-button';
import './elements/hot-scroll-view';
import './elements/hot-page';
import './elements/hot-footer';
import './elements/hot-mini-nav';
import './elements/hot-mini-nav-item';
import './elements/hot-accordion';
import './elements/hot-accordion-group';
import './elements/hot-bottom-sheet';
import './elements/hot-sheet-header';
import './elements/hot-select';
import './elements/hot-select-option';
import './elements/hot-listing-item';
import './elements/hot-info-button';
import './elements/hot-quantity';
import './elements/hot-cart-item';
import './elements/hot-cart-tax';
import './elements/hot-cart';
import './elements/hot-form-control';
import './elements/hot-payment-option';
import './elements/hot-sheet-text';
import './elements/hot-language-switch';

import { App as Application, SW as ServiceWorker } from './main';

Application.init();
ServiceWorker.init();
