import DataStorage from 'application-frame/core/DataStorage';

const pStore = Symbol('EntitiesAggregation.store');

export const EntitiesAggregation = {
    [pStore]: null,

    get entities() {
        return this[pStore].lazyCollection(this.value?.entities);
    },

    new(value, entityStore) {
        const instance = ({
            [pStore]: entityStore,

            __proto__: this,
        }).constructor();

        instance.fill(value);

        return instance;
    },

    __proto__: DataStorage,
};

export default EntitiesAggregation;
