import { Entity, pStore } from './Entity';
import DataStorage from 'application-frame/core/DataStorage';

const lazyMap = new WeakMap();

export const EntityCollection = {
    [pStore]: null,
    [Entity.isEntity]: true,

    get(index) {
        if (!this.value?.[index]) {
            throw new Error('undefined index ${index} in EntityCollection');
        }

        const item = this.value[index];

        if (!item[Entity.isEntity]) {
            this.set(index, this[pStore].createOrUpdate(item));
        }

        return this.value[index];
    },

    set(index, entity) {
        if (!entity?.[Entity.isEntity]) {
            throw new Error('provided value is not a shopware entity');
        }

        this.value[index] = entity;
    },

    get length() {
        return this.value.length;
    },

    new(value, entityStore) {
        const instance = ({ [pStore]: entityStore, __proto__: this }).constructor();

        if (value) {
            instance.fill(Array.from(value));
        }

        return instance;
    },

    newLazy(list, entityStore) {
        if (!list) {
            return null;
        }

        if(!lazyMap.has(list)) {
            lazyMap.set(list, this.new(list, entityStore));
        }

        return lazyMap.get(list);
    },

    [Symbol.iterator]: function*() {
        for (let i = 0; i < this.length; i++) {
            yield this.get(i);
        }
    },

    __proto__: DataStorage,
};

export default EntityCollection;
