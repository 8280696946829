import { Customer } from './Customer';

export const OrderCustomer = {
    get orderId() {
        return this.value?.orderId;
    },

    __proto__: Customer,
};

export default OrderCustomer;
