import { Entity } from './Entity';

export const Salutation = {

    get salutationKey() {
        return this.value?.salutationKey;
    },

    get displayName() {
        return this.value?.translated?.displayName ?? this.value?.displayName;
    },

    get letterName() {
        return this.value?.translated?.letterName ?? this.value?.letterName;
    },

    __proto__: Entity,
};

export default Salutation;
