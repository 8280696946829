import { Entity } from './Entity';

export const Currency = {

    get isoCode() {
        return this.value?.isoCode;
    },

    get symbol() {
        return this.value?.symbol;
    },

    get name() {
        return this.value?.translated?.name ?? this.value?.name;
    },

    get shortName() {
        return this.value?.translated?.shortName ?? this.value?.shortName;
    },

    get decimalPrecision() {
        return this.value?.decimalPrecision;
    },

    get factor() {
        return this.value?.factor;
    },

    __proto__: Entity,
};

export default Currency;
