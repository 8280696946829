import { Entity } from './Entity';

export const ShippingMethod = {

    get name() {
        return this.value?.translated?.name ?? this.value?.name;
    },

    get min() {
        return this.value?.min;
    },

    get max() {
        return this.value?.max;
    },

    get unit() {
        return this.value?.unit;
    },

    __proto__: Entity,
};

export default ShippingMethod;
