import { SubscriberFeature } from 'application-frame/core/features/SubscriberFeature';
import DataBinding from '@af-modules/databinding';

import LanguageManager from '../managers/Language';

const viewFactories = new Map();

export const createView = function(viewFactory, component) {
    viewFactories.set(component, viewFactory);

    return viewFactory;
};

export const defaultViewFactory = function(component) {
    return {
        get strings() {
            return component.strings;
        },

        l10n(...args) {
            return LanguageManager.translate(...args);
        },
    };
};

const Callbacks = {
    onL10nChanged: Symbol('Component.Callbacks.onL10nChanged'),
};

export const Component = {
    inflated: false,
    strings: null,
    scope: null,
    nodes: null,
    name: 'UnnamedComponent',
    id: null,

    [Callbacks.onL10nChanged]() {
        if (!this.scope) {
            return;
        }

        this.scope.update();
    },

    init(template) {
        const prototype = Object.getPrototypeOf(this);
        const viewFactory = viewFactories.get(this) ?? viewFactories.get(prototype);

        if (!viewFactory) {
            throw new Error(`no view factory defined for component ${prototype.name}`);
        }

        const { scope, node } = DataBinding.createTemplateInstance({ template, scope: viewFactory(this) });

        this.scope = scope;
        this.scope.not = value => !value;
        this.nodes = Array.from(node.children);
        this.inflated = true;

        SubscriberFeature(LanguageManager, this);
    },

    get [LanguageManager.Events.Change]() { return this[Callbacks.onL10nChanged]; },

    new() {
        const id = Math.round(performance.now() * Math.random());

        return { id: `cmp-${id}`, __proto__: this };
    }
};

export default Component;
export { Callbacks as ComponentCallbacks };
