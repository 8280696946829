import { UploadableEntity } from './UploadableEntity';
import { Entity, pStore } from './Entity';
import { EntityStore } from './EntityStore';
import { EntityCollection } from './EntityCollection';
import { CartLineItem } from './CartLineItem';
import { Iterator } from '../Iterator';

const pQueue = Symbol('Cart.queue');

const CartTransaction = {

    /**
     * @type {symbol}
     */
    type: null,

    /**
     * @type {{ id: string, value: { api_alias: string } }}
     */
    entity: null,

    /**
     * @type {(number|null)}
     */
    quantity: null,

    /**
     * @type {(string|null)}
     */
    lineItemId: null,
};

const CartTransactionType = {
    Add: Symbol('CartTransaction.Add'),
    Remove: Symbol('CartTransaction.Remove'),
    Update: Symbol('CartTransaction.Update'),
};

const notify = function(cart) {
    if (cart[UploadableEntity.isDirty]) {
        return;
    }

    cart[pStore].entityChanged(cart);
    cart[UploadableEntity.isDirty] = true;
};

export const Cart = {

    /**
     * @type {Map<symbol, Set<CartTransaction>>}
     */
    [pQueue]: null,
    [UploadableEntity.isDirty]: false,

    get id() {
        return this.value?.apiAlias;
    },

    /**
     * @return {string}
     */
    get token() {
        return this.value?.token;
    },

    /**
     * @return {(EntityCollection|null)}
     */
    get items() {
        return this[pStore].lazyCollection(this.value?.lineItems);
    },

    get price() {
        return this.value?.price;
    },

    get totalCartCount() {
        const baseCount = Iterator.new(this.items).reduce((total, item) => total + item.quantity, 0) ?? 0;
        const pendingCount = Iterator.new(this[pQueue].values())
            .flatMap(set => set)
            .reduce((total, transaction) => {
                if (transaction.type === CartTransactionType.Add) {
                    return total + transaction.quantity;
                }

                if (transaction.type === CartTransactionType.Update) {
                    return total + transaction.quantity;
                }

                if (transaction.type === CartTransactionType.Remove) {
                    return total - transaction.quantity;
                }

                throw TypeError('transaction type is not a variant of CartTransactionType');
            }, 0);

        return baseCount + pendingCount;
    },

    /**
     * Adds a product or promotion to the cart.
     * @param {Entity} entity
     * @param {number} quantity
     *
     * @return {undefined}
     */
    add(entity, quantity = 1) {
        const type = CartTransactionType.Add;

        this[pQueue].get(type).add({ type, entity, quantity, __proto__: CartTransaction });
        notify(this);
    },

    /**
     * updates a product or promotion in the cart.
     * @param {CartLineItem} lineItem
     * @param {number} quantity
     * @param {string} referencedId
     *
     * @return {undefined}
     */
    update(lineItem, quantity = 0, referencedId = lineItem.referencedId) {
        const type = CartTransactionType.Update;

        this[pQueue].get(type).add({ type, lineItemId: lineItem.id, referencedId, quantity, __proto__: CartTransaction });
        notify(this);
    },

    /**
     * Removes a product or promotion from the cart.
     *
     * @param {CartLineItem} lineItem
     *
     * @return {undefined}
     */
    remove(lineItem) {
        const type = CartTransactionType.Remove;

        this[pQueue].get(type).add({ type, lineItemId: lineItem.id, quantity: lineItem.quantity, __proto__: CartTransaction });
        notify(this);
    },

    /**
     * @param  {object} value
     * @param  {EntityStore} entityStore
     *
     * @return {Cart}
     */
    new(value, entityStore) {
        const instance = super.new(value, entityStore);

        instance[pQueue] = new Map(Object.values(CartTransactionType).map(type => [type, new Set()]));

        return instance;
    },

    fill(value) {
        if (value.price) {
            value.price = this[pStore].createOrUpdate(value.price);
        }

        return super.fill(value);
    },

    [UploadableEntity.Callbacks.onUpload](api) {
        Iterator.new(this[pQueue].entries()).forEach(([type, list]) => {
            if(list.size === 0) {
                return;
            }

            // this maps all transactions into shopware API payload data.
            const transactions = Iterator.new(list).map(transaction => {
                if (type === CartTransactionType.Add) {
                    return {
                        type: transaction.entity.value.apiAlias,
                        referencedId: transaction.entity.id,
                        quantity: transaction.quantity
                    };
                }

                if (type === CartTransactionType.Update) {
                    return {
                        id: transaction.lineItemId,
                        referencedId: transaction.referencedId,
                        quantity: transaction.quantity,
                    };
                }

                if (type === CartTransactionType.Remove) {
                    return {
                        id: transaction.lineItemId,
                    };
                }

                throw new TypeError('transaction type is not any of CartTransactionType');

            // In the next step we remove all duplicate enties by adding up their quantities.
            // We should end up with one record for each referencedId or line item id.
            // This is needed as we can only update line item quantities and not add or subtract to/from them.
            }).dedupe(
                (transaction) => transaction.id ?? transaction.referencedId,
                (existingTransaction, transaction) => {
                    existingTransaction.quantity += transaction.id;

                    if (transaction.referencedId) {
                        existingTransaction.referencedId = transaction.referencedId;
                    }
                }

            // for all update transactions we want to keep the exisiting quantity,
            // but shopware will replace the quantity of the line item,
            // rather than add our new value. So we add the current quantity of the lineItem
            // to our payload data.
            ).map(transaction => {
                if (!transaction.id) {
                    return transaction;
                }

                const currentLineItem = Iterator.new(this.items).find(item => item.id === transaction.id);

                transaction.quantity += currentLineItem.quantity;

                return transaction;
            }).intoArray();

            api.cart(transactions).then(() => {
                this[pQueue].get(type).clear();
            });
        });

        this[UploadableEntity.isDirty] = false;
    },

    __proto__: Entity,
};

export default Cart;
