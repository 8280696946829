import { Entity, pStore } from './Entity';
import { EntityCollection } from './EntityCollection';

export const CartPrice = {
    TaxStatus: {
        Net: 'net',
        Gross: 'gross',
    },

    /**
     * @type {number}
     */
    get net() {
        return this.value?.netPrice;
    },

    /**
     * @type {number}
     */
    get gross() {
        return this.value?.totalPrice;
    },

    /**
     * @type {string}
     */
    get taxStatus() {
        return this.value?.taxStatus;
    },

    /**
     * @type {EntityCollection}
     */
    get calculatedTaxes() {
        return this[pStore].lazyCollection(this.value?.calculatedTaxes);
    },

    __proto__: Entity,
};

export default CartPrice;
