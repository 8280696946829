import { CustomElement, CustomElementMeta } from 'application-frame/web/CustomElement';
import DataBinding from '@af-modules/databinding';
import { Iterator } from '../../lib/Iterator';

import template from './template';

const HotLanguageSwitchMeta = {
    template,

    name: 'hot-language-switch',

    attributes: {
        value: 'string',
    },

    get object() { return HotLanguageSwitch; },

    __proto__: CustomElementMeta,
};

const meta = HotLanguageSwitchMeta;
const Private = {
    scope: Symbol('HotLanguageSwitch.scope'),
    view: Symbol('HotLanguageSwitch.view'),

    __proto__: meta.symbols,
};

const lightDomObserver = new MutationObserver((records) => {
    records.forEach(record => {
        const target = record.target[Private.scope] ?? record.target.parentElement[Private.scope];

        target.update();
    });
});

const createView = function(element) {
    return {
        get label() {
            return Iterator.new(element.children)
                .find(child => child.selected)?.textContent.trim();
        },

        get value() {
            return element.value;
        },

        set value(value) {
            element.value = value;
        },

        get options() {
            return element.options;
        },

        onSelectionChanged(event) {
            const selectedOption = this.options[event.target.selectedIndex];

            element.value = selectedOption.value;
            element.dispatchEvent(new Event('change'));
        },
    };
};

const HotLanguageSwitch = {

    value: null,

    get options() {
        return Iterator.new(this.children)
            .filter(child => child.localName === 'option')
            .intoArray();
    },

    /**
     * @type {string}
     */
    get [Private.value]() {
        return this.options.find(option => option.selected)?.value ?? null;
    },

    set [Private.value](value) {
        if (value === null) {
            return;
        }

        this.options.forEach(option => option.selected = option.value === value);
    },

    [Private.create]() {
        const view = createView(this);
        const { scope, node } = DataBinding.createTemplateInstance({ template, scope: view });

        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(node);

        this[Private.scope] = scope;
        this[Private.view] = view;

        lightDomObserver.observe(this, { childList: true, subtree: true, attributes: true });
    },

    [Private.onPropertyChanged]() {
        this[Private.scope].update();
    },

    connectedCallback() {
        super.connectedCallback();

        this.setAttribute('resolved', '');
    },

    __proto__: CustomElement,
};

meta.prepare(HotLanguageSwitch);
customElements.define(meta.name, meta.object.constructor);

export { HotLanguageSwitch, HotLanguageSwitchMeta };
