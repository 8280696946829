import Application from 'application-frame/core/Application';
import { ServiceWorker } from 'application-frame/web';

import { Router } from './lib/Router';
import SiteMetadataManager from './managers/SiteMetadata';
import ListingPage from './pages/Listing';
import FooterComponent from './components/Footer';
import getTemplate from './lib/getTemplate';
import LanguageManager from './managers/Language';
import Shopware from './managers/Shopware';
import Config from './managers/Config';
import { BottomSheetManager } from './managers/BottomSheet';
import { FilterPage } from './pages/Filter';
import { ProductListingManager } from './managers/ProductListing';
import { PersistentFiltersManager } from './managers/PersistentFilters';
import { CartManager } from './managers/Cart';
import { CurrencyManager } from './managers/Currency';
import { DialogManager } from './managers/Dialog';
import { ProductPage } from './pages/Product';
import { FoodLabelsPage } from './pages/FoodLabels';
import { CartPage } from './pages/Cart';
import { SignInPage } from './pages/SignIn';
import { PaymentPage } from './pages/Payment';
import { CompletePage } from './pages/Complete';
import { ContentPage } from './pages/Content';

export const App = {

    routes: [
        ListingPage,
        FilterPage,
        ProductPage,
        FoodLabelsPage,
        CartPage,
        SignInPage,
        PaymentPage,
        CompletePage,
        ContentPage,
    ],

    globalComponents: {
        footer: FooterComponent,
    },

    managers: [
        Shopware,
        LanguageManager,
        SiteMetadataManager,
        BottomSheetManager,
        ProductListingManager,
        PersistentFiltersManager,
        CartManager,
        CurrencyManager,
        DialogManager,
    ],

    init() {
        this.constructor();
        Config.init();
        this.managers.forEach(manager => manager.init(Config));

        Router.init([]);

        this.routes.forEach(route => {
            Router.addRoutable(route.path, route);
        });

        Object.keys(this.globalComponents).forEach(key => {
            const template = getTemplate(this.globalComponents[key].template);

            this.globalComponents[key] = this.globalComponents[key].new();
            this.globalComponents[key].init(template);
        });

        Router.restore();
    },

    __proto__: Application,
};

export const SW = {
    script: './js/sw.js',
    scope: './',

    init() {
        if (!this.isSupported) {
            return;
        }

        const superResult = super.init();

        this.checkUpdate();

        return superResult;
    },

    __proto__: ServiceWorker,
};

export default App;
