import { Entity, pStore } from './Entity';

export const Product = {
    get name() {
        return this.value?.translated?.name ?? this.value?.name;
    },

    get description() {
        return this.value?.translated?.description ?? this.value?.description;
    },

    get metaDescription() {
        return this.value?.translated?.metaDescription ?? this.value?.metaDescription;
    },

    get keywords() {
        return this.value?.translated?.keywords ?? this.value?.keywords;
    },

    get metaTitle() {
        return this.value?.translated?.metaTitle ?? this.value?.metaTitle;
    },

    get price() {
        return this.value?.calculatedPrice;
    },

    get categoryTree() {
        return this.value?.categoryTree.slice(0);
    },

    get variation() {
        return this.value?.variation.slice(0);
    },

    get cover() {
        return this.value?.cover;
    },

    get properties() {
        return this[pStore].lazyCollection(this.value?.properties);
    },

    get options() {
        return this[pStore].lazyCollection(this.value?.options);
    },

    get optionIds() {
        return this.value?.optionIds?.slice(0);
    },

    get parentId() {
        return this.value?.parentId;
    },

    get minPurchase() {
        return this.value?.minPurchase;
    },

    get maxPurchase() {
        return this.value?.maxPurchase ?? this.availableStock;
    },

    get availableStock() {
        return this.value?.availableStock;
    },

    get customFields() {
        return this.value?.customFields;
    },

    fill(value) {
        value.calculatedPrice = this[pStore].createOrUpdate(value.calculatedPrice);

        if (value.cover) {
            value.cover = this[pStore].createOrUpdate(value.cover);
        }

        if (value.customFields) {
            Object.freeze(value.customFields);
        }

        return super.fill(value);
    },

    __proto__: Entity,
};

export default Product;
