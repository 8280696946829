import { Entity } from './Entity';

export const CmsPageText = {
    get content() {
        return this.value?.content;
    },

    __proto__: Entity,
};

export default CmsPageText;
