import { BindingApi } from '@af-modules/databinding';

const ValidityBinding = {

    name: 'bind-validity',

    node: null,
    dataPath: null,

    constructor({ text, node }) {
        this.dataPath = text;
        this.node = node;

        this.node.addEventListener('focusin', this.onChange.bind(this));
        this.node.addEventListener('focusout', this.onChange.bind(this));
        this.node.addEventListener('click', this.onChange.bind(this));
        this.node.addEventListener('change', this.onChange.bind(this));
        this.node.addEventListener('input', this.onChange.bind(this));

        BindingApi(this);
    },

    _make(...args) {
        this.constructor(...args);
    },

    update() {},

    onChange() {
        BindingApi(this).scheduleScopeUpdate(scope => {
            const { assignExpression } = BindingApi(this).parser;

            assignExpression(this.dataPath, scope, this.node.checkValidity());
        });
    },

    __proto__: BindingApi().Binding,
};

BindingApi().registerBinding(ValidityBinding);

export default ValidityBinding;
