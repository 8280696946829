import { Entity, pStore } from './Entity';

export const CmsPageProductBox = {
    get product() {
        return this[pStore].get(this.value?.productId);
    },

    fill(value) {
        if (value.product) {
            this[pStore].createOrUpdate(value.product);
        }

        super.fill(value);
    },

    __proto__: Entity,
};

export default CmsPageProductBox;
