import { HTMLElement } from 'application-frame/core/nativePrototype';

import defineCustomElement from '../../lib/defineCustomElement';
import template from './template';

const HotSheetHeaderMeta = {
    name: 'hot-sheet-header',
    template,
    get object() { return HotSheetHeader; },
};

const meta = HotSheetHeaderMeta;

const HotSheetHeader = {
    constructor: function HotSheetHeader() {
        const instance = HTMLElement.constructor.apply(this);

        instance._create();

        return instance;
    },


    _create() {
        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(document.importNode(template.content, true));
    },

    connectedCallback() {
        this.setAttribute('resolved', '');
    },

    __proto__: HTMLElement,
};

defineCustomElement(meta);

export { HotSheetHeader, HotSheetHeaderMeta };
