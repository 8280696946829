import { Component, ComponentCallbacks } from './Component';

export const Page = {
    name: 'UnnamedPage',
    active: false,

    [ComponentCallbacks.onL10nChanged]() {
        if (!this.active) {
            return;
        }

        super[ComponentCallbacks.onL10nChanged]();
    },

    onRouteEnter() {
        this.lazyInit();

        this.active = true;
        this.scope.update();
    },

    onRouteLeave() {
        this.active = false;
        this.scope.update();
    },

    lazyInit() {
        if (!this.template || this.inflated) {
            return;
        }

        this.init(`#${this.template}`);
    },

    __proto__: Component,
};

export default Page;

export { createView, defaultViewFactory } from './Component';
