import { Page, defaultViewFactory, createView } from '../prototypes/Page';
import { BottomSheetManager } from '../managers/BottomSheet';
import { ProductListingManager } from '../managers/ProductListing';
import { PersistentFiltersManager } from '../managers/PersistentFilters';
import { Aggregations } from '../lib/shopware/AggregationCollection';

const Callbacks = {
    onListingReady: Symbol('FilterPage.Callbacks.onListingReady'),
    onListingUpdate: Symbol('FilterPage.Callbacks.onListingUpdate'),
};

export const FilterPage = {
    name: 'FilterPage',
    path: '/filter',
    template: 'page-filter',
    listing: null,
    filters: [],

    get aggregations() {
        return this.listing?.aggregations.get(Aggregations.Properties);
    },

    init(template) {
        super.init(template);

        ProductListingManager.ready.then(this[Callbacks.onListingReady].bind(this));
    },

    onRouteEnter() {
        super.onRouteEnter();

        BottomSheetManager.request(this);
    },

    onRouteLeave() {
        super.onRouteLeave();

        BottomSheetManager.relase(this);
    },

    [Callbacks.onListingReady](listing) {
        this.listing = listing;
        this.listing.when(this[Callbacks.onListingUpdate].bind(this));

        ProductListingManager.initialLoad(ProductListingManager.LoadReason.Filter);
    },

    [Callbacks.onListingUpdate]() {
        const aggregations = this.aggregations?.entities ?? [];

        this.filters = Array.from(aggregations).filter(filter => filter.filterable);
        this.scope.update();
    },

    __proto__: Page,
};

createView((page) => {
    return {
        filterStates: new WeakMap(),

        get filters() {
            return page.filters;
        },

        get listing() {
            return page.listing;
        },

        asArray(value) {
            if (!value) {
                return [];
            }

            return Array.from(value);
        },

        getSelectedIds(filterId) {
            return PersistentFiltersManager.getFilter('propertyIds', filterId);
        },

        isSelected(filterId, optionId) {
            return this.getSelectedIds(filterId).includes(optionId);
        },

        /**
         * This listener has to be attached in a bind-repeat child scope
         *
         * @param  {Event} event
         * @param  {ScopePrototype} scope
         *
         * @return {undefined}
         */
        onOptionChanged(event, scope) {
            const filterDefinition = scope.filter;

            const newSelection = event.target.selectedOptions.map(option => option.value);
            const currentSelection = this.getSelectedIds(filterDefinition.id);

            const removed = currentSelection.filter(id => !newSelection.includes(id));
            const added = newSelection.filter(id => !currentSelection.includes(id));

            removed.forEach(optionId => {
                PersistentFiltersManager.removeFilter('propertyIds', filterDefinition.id, optionId);
            });

            added.forEach(optionId => {
                PersistentFiltersManager.addFilter('propertyIds', filterDefinition.id, optionId);
            });
        },

        __proto__: defaultViewFactory(page),
    };
}, FilterPage);

export default FilterPage;
