import { Entity, pStore } from './Entity';

export const CmsPage = {
    get name() {
        return this.value?.translated?.name ?? this.value?.name;
    },

    get sections() {
        return this[pStore].lazyCollection(this.value?.sections);
    },

    __proto__: Entity,
};

export default CmsPage;
