import { Page, defaultViewFactory, createView } from '../prototypes/Page';
import { BottomSheetManager } from '../managers/BottomSheet';
import { Shopware } from '../managers/Shopware';
import { Context } from '../lib/shopware/Context';
import { EntityCollection } from '../lib/shopware/EntityCollection';
import { Salutation } from '../lib/shopware/Salutation';
import { Iterator } from '../lib/Iterator';
import { Router } from '../lib/Router';

export const SignInPage = {
    name: 'SignInPage',
    path: '/sign-in',
    template: 'page-signin',

    /** @type {Promise[]} * */
    dependencies: null,

    /** @type {Context} */
    context: null,

    /** @type {EntityCollection.<Salutation>} */
    salutations: null,

    init(template) {
        super.init(template);

        this.dependencies = [
            Shopware.context.then(context => this.context = context),
            Shopware.salutations().then(salutations => this.salutations = salutations)
        ];
    },

    onRouteEnter() {
        super.onRouteEnter();

        const dependencies = Promise.all(this.dependencies)
            .then(() => { // redirect user if there is already a user account
                if (!this.context.customer) {
                    return;
                }

                Router.switchTo('/cart');
            });

        BottomSheetManager.request(this);
        BottomSheetManager.busy(this, dependencies);
    },

    onRouteLeave() {
        super.onRouteLeave();

        BottomSheetManager.relase(this);
    },

    __proto__: Page,
};

createView((page) => {
    return {
        strings: {
            title: 'account.registerTitle',
            description: 'account.loginHeader',
            firstNamePlaceholder: 'account.personalFirstNamePlaceholder',
            firstNameLabel: 'account.personalFirstNameLabel',
            lastNamePlaceholder: 'account.personalLastNamePlaceholder',
            lastNameLabel: 'account.personalLastNameLabel',
            emailAddessPlaceholder: 'account.personalMailPlaceholder',
            emailAddessLabel: 'account.personalMailLabel',
            register: 'account.registerSubmit',
        },

        email: '',
        firstName: '',
        lastName: '',
        valid: false,

        onRegister() {
            if (!this.valid) {
                return;
            }

            const salutation = Iterator.new(page.salutations)
                .find(item => item.salutationKey === 'not_specified');

            const pendingTask = Shopware.account({
                email: this.email,
                salutationId: salutation?.id,
                firstName: this.firstName,
                lastName: this.lastName,
                guest: true,
                storefrontUrl: `${location.protocol}//${location.host}`,
                billingAddress: {
                    salutationId: salutation?.id,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    street: 'empty',
                    zipcode: '00000',
                    city: 'empty',
                    countryId: page.context?.salesChannel.countryId,
                }
            }).then(() => Router.switchTo('/payment'));

            BottomSheetManager.busy(page, pendingTask);
        },

        __proto__: defaultViewFactory(page),
    };
}, SignInPage);
