import DataBinding from '@af-modules/databinding';

const createView = function(host) {
    return {
        get pageTitle() { return host.title; },
    };
};

const SiteMetadata = {
    template: '#dynamic-head',
    scope: null,

    title: 'House of Tirrée',

    init() {
        const { scope } = DataBinding.createTemplateInstance({ scope: createView(this), template: this.template });

        this.scope = scope;
    },
};

export default SiteMetadata;
