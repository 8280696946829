import { Component, createView, defaultViewFactory } from '../prototypes/Component';
import { CartManager } from '../managers/Cart';
import { EventSubscriberFeature } from '../lib/EventSubscriberFeature';
import { Router } from '../lib/Router';

export const FooterComponent = {
    name: 'FooterComponent',
    template: 'component-footer',

    view: null,
    scope: null,

    get cart() {
        return CartManager;
    },

    strings: {
        label: 'hotWaiter.shop.title',
    },

    init(template) {
        super.init(template);

        EventSubscriberFeature(CartManager, this);
    },

    [CartManager.Events.Count]() {
        this.scope.update();
    },

    __proto__: Component,
};

createView((component) => {
    return {
        get cartQuantity() {
            if (component.cart.productCount < 1) {
                return false;
            }

            return component.cart.productCount;
        },

        onOpenBasket(event) {
            Router.switchTo('/cart');
            event.preventRecycle();
        },

        __proto__: defaultViewFactory(component),
    };
}, FooterComponent);

export default FooterComponent;
