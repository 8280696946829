export const Preferences = {
    contextId: 'sw-context-id',
};

export const LocalPreferencesManager = {

    getPreference(name) {
        return localStorage.getItem(name);
    },

    setPreference(name, value) {
        return localStorage.setItem(name, value);
    }
};

export default LocalPreferencesManager;
