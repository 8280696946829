import { Entity, pStore } from './Entity';

export const Language = {
    get parentId() {
        return this.value?.parentId;
    },

    get translationCode() {
        return this.value?.translationCode;
    },

    get name() {
        return this.value?.name;
    },

    get localeId() {
        return this.value?.localeId;
    },

    fill(value) {
        value.translationCode = this[pStore].createOrUpdate(value.translationCode);

        return super.fill(value);
    },

    __proto__: Entity,
};

export default Language;
