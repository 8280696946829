import { Entity } from './Entity';

export const Country = {

    get name() {
        return this.value?.translated?.name ?? this.value?.name;
    },

    get iso() {
        return this.value?.iso;
    },

    get iso3() {
        return this.value?.iso3;
    },

    get shippingAvailable() {
        return this.value?.shippingAvailable;
    },

    get taxFree() {
        return this.value?.taxFree;
    },

    get active() {
        return this.value?.active;
    },

    get displayStateInRegistration() {
        return this.value?.displayStateInRegistration;
    },

    get forceStateInRegistration() {
        return this.value?.forceStateInRegistration;
    },

    __proto__: Entity,
};

export default Country;
