import { HTMLElement } from 'application-frame/core/nativePrototype';
import DataBinding from '@af-modules/databinding';

import defineCustomElement from '../../lib/defineCustomElement';
import template from './template';

const HotSelectMeta = {
    name: 'hot-select',
    template,
    attributes: ['title'],
    get object() { return HotSelect; },
};

const meta = HotSelectMeta;
const pScope = Symbol('HotSelect.scope');
const pView = Symbol('HotSelect.view');

const createView = function(element) {
    return {
        get groupTitle() {
            return element.title;
        },

        onSelectOption(event) {
            if (!('selected' in event.target)) {
                return;
            }

            if (event.target.selected) {
                if (element.required) {
                    return;
                }

                event.target.selected = false;
                element.dispatchEvent(new Event('change', { bubbles: true }));

                return;
            }

            if (!element.multiple) {
                element.selectedOptions.forEach(option => option.selected = false);
            }

            event.target.selected = true;
            element.dispatchEvent(new Event('change', { bubbles: true }));
        },
    };
};

const HotSelect = {

    get title() {
        return this.getAttribute('title');
    },

    set title(value) {
        this.setAttribute('title', value);
    },

    get multiple() {
        return this.hasAttribute('multiple');
    },

    set multiple(value) {
        value ? this.setAttribute('multiple', '') : this.removeAttribute('multiple');
    },

    get options() {
        return Array.from(this.children).filter(child => 'selected' in child);
    },

    get selectedOptions() {
        return this.options.filter(child => child.selected);
    },

    get selectedIndex() {
        const selected = this.selectedOptions[0];

        return selected ? this.options.indexOf(selected) : -1;
    },

    get value() {
        return this.selectedOptions[0]?.value;
    },

    get required() {
        return this.hasAttribute('required');
    },

    set required(value) {
        value ? this.setAttribute('required', '') : this.removeAttribute('required');
    },

    constructor: function HotSelect() {
        const instance = HTMLElement.constructor.apply(this);

        instance._create();

        return instance;
    },

    _create() {
        const view = createView(this);
        const { scope, node } = DataBinding.createTemplateInstance({ template, scope: view });

        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(node);

        this[pScope] = scope;
        this[pView] = view;

        DataBinding.attachBindings(this[pScope], this, [
            { selector: 'root', name: 'bind-event', parameter: 'click', value: 'view.onSelectOption' },
        ]);
    },


    connectedCallback() {
        this.setAttribute('resolved', '');
    },

    attributeChangedCallback() {
        this[pScope].update();
    },

    __proto__: HTMLElement,
};

defineCustomElement(meta);

export { HotSelect, HotSelectMeta };
