import { Entity, pStore } from './Entity';

export const PropertyGroupOption = {
    get name() {
        return this.value?.translated?.name ?? this.value?.name;
    },

    get position() {
        return this.value?.translated?.position ?? this.value?.position;
    },

    get groupId() {
        return this.value?.groupId;
    },

    get colorHexCode() {
        return this.value?.colorHexCode;
    },

    get group() {
        return this[pStore].get(this.groupId);
    },

    fill(value) {
        super.fill(value);

        if (!this[pStore].has(this.groupId) && value.group) {
            this[pStore].createOrUpdate(value.group);
        }
    },

    __proto__: Entity,
};

export default PropertyGroupOption;
