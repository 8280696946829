import { Entity, pStore } from './Entity';

export const SalesChannel = {
    get name() {
        return this.value?.translated?.name ?? this.value?.name;
    },

    get shortName() {
        return this.value?.translated?.shortName ?? this.value?.shortName;
    },

    get languageId() {
        return this.value?.languageId;
    },

    get currencyId() {
        return this.value?.currencyId;
    },

    get paymentMethodId() {
        return this.value?.paymentMethodId;
    },

    get shippingMethodId() {
        return this.value?.shippingMethodId;
    },

    get countryId() {
        return this.value?.countryId;
    },

    get navigationCategory() {
        return {
            id: this.value?.navigationCategoryId,
            depth: this.value?.navigationCategoryDepth ?? 0,
        };
    },

    get footerCategoryId() {
        return this.value?.footerCategoryId;
    },

    get serviceCategoryId() {
        return this.value?.serviceCategoryId;
    },

    get accessKey() {
        return this.value?.accessKey;
    },

    get taxCalculationType() {
        return this.value?.taxCalculationType;
    },

    get customerGroupId() {
        return this.value?.customerGroupId;
    },

    get paymentMethodIds() {
        return this.value?.paymentMethodIds?.slice(0);
    },

    get currency() {
        const currency = this.value?.currency;

        if (!currency) {
            return null;
        }

        return this[pStore].get(currency.id);
    },

    fill(value) {
        if (value.currency) {
            this[pStore].createOrUpdate(value.currency);
        }

        return super.fill(value);
    },

    __proto__: Entity,
};

export default SalesChannel;
