import { HTMLElement } from 'application-frame/core/nativePrototype';
import DataBinding from '@af-modules/databinding';

import defineCustomElement from '../../lib/defineCustomElement';
import Iterator from '../../lib/Iterator';
import template from './template';

const HotCartTaxMeta = {
    name: 'hot-cart-tax',
    template,
    get object() { return HotCartTax; },
};

const meta = HotCartTaxMeta;
const pScope = Symbol('HotButton.scope');
const pView = Symbol('HotButton.view');

const createView = function() {
    return {
        head: null,
        rows: null,
    };
};

const iterateAncesstors = function*(node) {
    let parent = node;

    while (parent) {
        yield parent;

        parent = parent.parentElement;
    }
};

const observer = new MutationObserver(records => {
    records.forEach(record => {
        if (record.type !== 'childList' && record.type !== 'characterData') {
            return;
        }

        const target = Iterator.new(iterateAncesstors(record.target))
            .find(node => node.localName === meta.name);

        updatContent(target);
    });
});

const updatContent = function(element) {
    const headRow = Iterator.new(element.children)
        .find(child => child.getAttribute('type') === 'head');

    if (headRow) {
        element[pView].head = Iterator.new(headRow.children)
            .map(cell => cell.textContent)
            .intoArray();
    }

    element[pView].rows = Iterator.new(element.children)
        .filter(child => !child.hasAttribute('type'))
        .map(child =>
            Iterator.new(child.children)
                .map(cell => cell.textContent)
                .intoArray()
        ).intoArray();

    element[pScope].update();
};

const HotCartTax = {
    constructor: function HotHeader() {
        const instance = HTMLElement.constructor.apply(this);

        instance._create();

        return instance;
    },

    _create() {
        const view = createView(this);
        const { scope, node } = DataBinding.createTemplateInstance({ template, scope: view });

        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(node);

        this[pScope] = scope;
        this[pView] = view;
    },

    connectedCallback() {
        this.setAttribute('resolved', '');

        updatContent(this);

        observer.observe(this, {
            childList: true,
            subtree: true,
            characterData: true,
        });
    },

    __proto__: HTMLElement,
};

defineCustomElement(meta);

export { HotCartTax, HotCartTaxMeta };
