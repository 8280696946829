import { HTMLElement } from 'application-frame/core/nativePrototype';
import DataBinding from '@af-modules/databinding';

import defineCustomElement from '../../lib/defineCustomElement';
import template from './template';

const HotButtonMeta = {
    name: 'hot-button',
    template,
    attributes: ['disabled', 'href'],
    get object() { return HotButton; },
};

const meta = HotButtonMeta;
const pScope = Symbol('HotButton.scope');
const pView = Symbol('HotButton.view');

const createView = function(element) {
    return {
        get href() {
            return element.href;
        },

        get hasHref() {
            return !element.disabled && element.href?.length > 0;
        },

        onClick(event) {
            if (!this.disabled) {
                return;
            }

            event.stopPropagation();
            event.stopImmediatePropagation();

            return event.preventDefault();
        },
    };
};

const HotButton = {
    [pScope]: null,
    [pView]: null,

    get href() {
        return this.getAttribute('href');
    },

    set href(value) {
        this.setAttribute('href', value);
    },

    get disabled() {
        return this.hasAttribute('disabled');
    },

    set disabled(value) {
        value ? this.setAttribute('disabled', '') : this.removeAttribute('disabled');
    },

    constructor: function HotHeader() {
        const instance = HTMLElement.constructor.apply(this);

        instance._create();

        return instance;
    },

    _create() {
        const view = createView(this);
        const { scope, node } = DataBinding.createTemplateInstance({ template, scope: view });

        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(node);

        this[pScope] = scope;
        this[pView] = view;

        DataBinding.attachBindings(scope, this, [
            { selector: 'root', name: 'bind-ripple', value: '#ripple' },
            { selector: 'root', name: 'bind-event', parameter: 'click', value: 'view.onClick' },
        ]);
    },

    connectedCallback() {
        this.setAttribute('resolved', '');
    },

    attributeChangedCallback() {
        this[pScope].update();
    },

    __proto__: HTMLElement,
};

defineCustomElement(meta);

export { HotButton, HotButtonMeta };
