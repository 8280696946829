import { HTMLElement } from 'application-frame/core/nativePrototype';
import DataBinding from '@af-modules/databinding';

import defineCustomElement from '../../lib/defineCustomElement';
import template from './template';

const HotQuantityMeta = {
    name: 'hot-quantity',
    template,
    attributes: ['title', 'min', 'max', 'value'],
    get object() { return HotQuantity; },
};

const meta = HotQuantityMeta;
const pScope = Symbol('HotQuantity.scope');
const pView = Symbol('HotQuantity.view');

const createView = function(element) {
    return {
        get title() {
            return element.title;
        },

        get value() {
            return element.value;
        },

        set value(value) {
            element.value = value;
            element.dispatchEvent(new Event('input'));
        },

        get min() {
            return element.min;
        },

        get max() {
            return element.max;
        },

        onIncrease() {
            this.value += 1;
        },

        onReduce() {
            this.value -= 1;
        }
    };
};

const HotQuantity = {

    get title() {
        return this.getAttribute('title');
    },

    set title(value) {
        this.setAttribute('title', value);
    },

    get value() {
        return Number.parseFloat(this.getAttribute('value') ?? 0);
    },

    set value(value) {
        this.setAttribute('value', Math.min(Math.max(value, this.min), this.max));
    },

    get min() {
        return Number.parseFloat(this.getAttribute('min') ?? 0);
    },

    set min(value) {
        this.setAttribute('min', value);
    },

    get max() {
        return Number.parseFloat(this.getAttribute('max') ?? 0);
    },

    set max(value) {
        this.setAttribute('max', value);
    },

    constructor: function HotQuantity() {
        const instance = HTMLElement.constructor.apply(this);

        instance._create();

        return instance;
    },

    _create() {
        const view = createView(this);
        const { scope, node } = DataBinding.createTemplateInstance({ template, scope: view });

        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(node);

        this[pScope] = scope;
        this[pView] = view;
    },


    connectedCallback() {
        this[pScope].update();

        this.setAttribute('resolved', '');
    },

    attributeChangedCallback(name) {
        if (name === 'min' || name === 'max') {
            this.value += 0;
        }

        this[pScope].update();
    },

    __proto__: HTMLElement,
};

defineCustomElement(meta);

export { HotQuantity, HotQuantityMeta };
