import { HTMLElement } from 'application-frame/core/nativePrototype';
import DataBinding from '@af-modules/databinding';

import defineCustomElement from '../../lib/defineCustomElement';
import template from './template';

const HotSelectOptionMeta = {
    name: 'hot-select-option',
    template,
    get object() { return HotSelectOption; },
};

const meta = HotSelectOptionMeta;
const pScope = Symbol('HotSelectOption.scope');
const pView = Symbol('HotSelectOption.view');

const createView = function() {
    return {};
};

const HotSelectOption = {
    get selected() {
        return this.hasAttribute('selected');
    },


    set selected(value) {
        value ? this.setAttribute('selected', '') : this.removeAttribute('selected');
    },

    get value() {
        return this.getAttribute('value');
    },

    set value(value) {
        this.setAttribute('value', value);
    },

    constructor: function HotSelectOption() {
        const instance = HTMLElement.constructor.apply(this);

        instance._create();

        return instance;
    },

    _create() {
        const view = createView(this);
        const { scope, node } = DataBinding.createTemplateInstance({ template, scope: view });

        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(node);

        this[pScope] = scope;
        this[pView] = view;

        DataBinding.attachBindings(scope, this, [
            { selector: 'root', name: 'bind-ripple', value: '#ripple' }
        ]);
    },

    connectedCallback() {
        this.setAttribute('resolved', '');
    },

    __proto__: HTMLElement,
};

defineCustomElement(meta);

export { HotSelectOption, HotSelectOptionMeta };
