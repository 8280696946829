import { Entity, pStore } from './Entity';
import { AggregationCollection } from './AggregationCollection';

const pFilters = Symbol('Listing.filters');
const pPostFilters = Symbol('Listing.postFilters');
const pAggregations = Symbol('Listing.aggregations');
const pId = Symbol('Listing.categoryId');

export const Listing = {
    [pFilters]: null,
    [pPostFilters]: null,
    [pId]: null,
    [pAggregations]: null,

    get categoryId() {
        return this[pId];
    },

    get sorting() {
        return this.value?.sorting;
    },

    get page() {
        return this.value?.page;
    },

    get limit() {
        return this.value?.limit;
    },

    get total() {
        return this.value?.total;
    },

    get currentFilters() {
        return JSON.parse(JSON.stringify(this.value?.currentFilters));
    },

    get elements() {
        return this[pStore].lazyCollection(this.value?.elements);
    },

    get aggregations() {
        return this[pAggregations];
    },

    get filters() {
        return Array.from(this[pFilters]);
    },

    get postFilters() {
        return Array.from(this[pPostFilters]);
    },

    get isEmpty() {
        return !this.value;
    },

    addFilter({ type, field, value, parameters }) {
        this[pFilters].add({ type, field, value, parameters });

        return this;
    },

    removeFilter(filterDefinition) {
        this[pFilters].delete(filterDefinition);

        return this;
    },

    addPostFilter({ type, field, value, parameters }) {
        this[pPostFilters].add({ type, field, value, parameters });

        return this;
    },

    removePostFilter(filterDefinition) {
        this[pPostFilters].delete(filterDefinition);

        return this;
    },

    refresh({ limit = this.limit, page = this.page, sorting = this.sorting } = {}) {
        limit = limit ?? 100;
        page = page ?? 1;
        sorting = sorting ?? 'name-asc';

        this[pStore].refreshEntity(this, { limit, page, sorting });
    },

    new(categoryId, entityStore) {
        if (!categoryId) {
            throw new Error('categoryId for new Listing can not be empty');
        }

        const instance = ({
            [pId]: categoryId,
            [pStore]: entityStore,
            [pFilters]: new Set(),
            [pPostFilters]: new Set(),
            [pAggregations]: AggregationCollection.new(null, entityStore),

            __proto__: this,
        }).constructor();

        return instance;
    },

    fill(value) {
        this[pAggregations].fill(value.aggregations);

        return super.fill(value);
    },

    __proto__: Entity,
};

export default Listing;
