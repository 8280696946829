import { Page, defaultViewFactory, createView } from '../prototypes/Page';
import { BottomSheetManager } from '../managers/BottomSheet';
import { Router } from '../lib/Router';
import { Shopware } from '../managers/Shopware';
import { CurrencyManager } from '../managers/Currency';
import { EventSubscriberFeature } from '../lib/EventSubscriberFeature';
import { CartManager } from '../managers/Cart';

export const ProductPage = {
    name: 'ProductPage',
    path: '/product/{productId}',
    template: 'page-product',
    productId: null,
    product: null,
    quantity: 0,

    pendingProductOptions: new Set(),
    productOptionsCache: new Map(),

    init(template) {
        super.init(template);

        EventSubscriberFeature(CurrencyManager, this);
    },

    onRouteEnter(path, params) {
        Object.assign(this, params);
        super.onRouteEnter();

        if (!this.productId) {
            return Router.up();
        }

        const [productId, ...optionIds] = this.productId.split('-');

        Shopware.product(productId, optionIds)
            .then(product => {
                this.product = product;
                this.scope.update();
            });

        this.quantity = 1;
        BottomSheetManager.request(this);
    },

    onRouteLeave() {
        super.onRouteLeave();

        BottomSheetManager.relase(this);
    },

    getProductOptions(productId) {
        if (!this.productOptionsCache.has(productId) && !this.pendingProductOptions.has(productId)) {
            Shopware.productOptions(productId).then(collection => {
                this.productOptionsCache.set(productId, collection);
                this.pendingProductOptions.delete(productId);
                this.scope?.update();
            });
        }

        if (this.pendingProductOptions.has(productId)) {
            return null;
        }

        return this.productOptionsCache.get(productId);
    },

    __proto__: Page,
};

createView((page) => {
    return {
        strings: {
            addToCart: 'detail.addProduct',
            quantity: 'detail.dataColumnQuantity',
            price: 'hotWaiter.detail.priceLabel',
        },

        get product() {
            return page.product;
        },

        get minQuantity() {
            const { minPurchase = 1, availableStock = 0 } = this.product ?? {};

            return Math.min(minPurchase, availableStock);
        },

        get maxQuantity() {
            const { maxPurchase = 1, availableStock = 0 } = this.product ?? {};

            return Math.min(maxPurchase, availableStock);
        },

        get quantity() {
            return page.quantity;
        },

        set quantity(value) {
            page.quantity = value;
        },

        optionGroups(productParentId) {
            if (!productParentId) {
                return null;
            }

            const productOptions = page.getProductOptions(productParentId);

            if (!productOptions) {
                return null;
            }

            const groups = Array.from(productOptions).reduce((groups, option) => {
                if (!groups.has(option.groupId)) {
                    groups.set(option.groupId, {
                        id: option.groupId,
                        name: option.group.name,
                        options: [],
                    });
                }

                groups.get(option.groupId).options.push(option);

                return groups;
            }, new Map());

            return Array.from(groups.values());
        },

        isActiveOption(optionId) {
            if (!this.product.options?.length) {
                return false;
            }

            return Array.from(this.product.options).some(option => option.id === optionId);
        },

        currency(number) {
            return CurrencyManager.format(number);
        },

        onVariantSelected(event, scope) {
            const path = Router.getCurrentPath();
            const options = Array.from(this.product.options)
                .filter(option => option.groupId !== scope.optionGroup.id)
                .map(option => option.id);

            options.push(event.target.value);

            const productId = `${this.product.parentId}-${options.join('-')}`;

            path[path.length - 1] = productId;

            location.replace(path.join('/'));
        },

        onAddToCart() {
            CartManager.add(this.product, this.quantity);
            Router.switchTo('/');
        },

        __proto__: defaultViewFactory(page),
    };
}, ProductPage);
