import { Entity, pStore } from './Entity';
import { SalesChannel } from './SalesChannel';
import { UploadableEntity } from './UploadableEntity';

const pChanges = Symbol('Context.changes');

export const Context = {

    [pChanges]: null,
    [UploadableEntity.isDirty]: false,

    get id() {
        return this.value?.apiAlias;
    },

    get token() {
        return this.value?.token;
    },

    /**
     * @type {SalesChannel}
     */
    get salesChannel() {
        return this[pStore].get(this.value?.salesChannel.id);
    },

    get currentCustomerGroup() {
        return this[pStore].get(this.value?.currentCustomerGroup.id);
    },

    get fallbackCustomerGroup() {
        return this[pStore].get(this.value?.fallbackCustomerGroup.id);
    },

    get currency() {
        return this[pStore].get(this.value?.currency.id);
    },

    get taxRules() {
        return this[pStore].lazyCollection(this.value?.taxRules);
    },

    get customer() {
        const customer = this.value?.customer;

        if (!customer) {
            return null;
        }

        return this[pStore].get(customer.id);
    },

    get paymentMethod() {
        const paymentMethod = this.value?.paymentMethod;

        if (!paymentMethod) {
            return null;
        }

        return this[pStore].get(paymentMethod.id);
    },

    get shippingMethod() {
        const shippingMethod = this.value?.shippingMethod;

        if (!shippingMethod) {
            return null;
        }

        return this[pStore].get(shippingMethod.id);
    },

    get shippingLocation() {
        const shippingLocation = this.value?.shippingLocation;

        if (!shippingLocation) {
            return null;
        }

        return this[pStore].get(shippingLocation.id);
    },

    get rulesIds() {
        return this.value?.rulesIds?.slice(0);
    },

    fill(value) {
        this[pStore].createOrUpdate(value.salesChannel);
        this[pStore].createOrUpdate(value.currentCustomerGroup);
        this[pStore].createOrUpdate(value.fallbackCustomerGroup);

        if (value.customer) {
            this[pStore].createOrUpdate(value.customer);
        }

        if (value.paymentMethod) {
            this[pStore].createOrUpdate(value.paymentMethod);
        }

        if (value.shippingMethod) {
            this[pStore].createOrUpdate(value.shippingMethod);
        }

        if (value.currency) {
            this[pStore].createOrUpdate(value.currency);
        }

        return super.fill(value);
    },

    modify({
        paymentMethodId, shippingMethodId, currencyId,
        languageId, countryId, countryStateId
    } = {}) {
        if (!this[pChanges]) {
            this[pChanges] = {};
        }

        if (paymentMethodId) {
            this[pChanges].paymentMethodId = paymentMethodId;
        }

        if (shippingMethodId) {
            this[pChanges].shippingMethodId = shippingMethodId;
        }

        if (currencyId) {
            this[pChanges].currencyId = currencyId;
        }

        if (languageId) {
            this[pChanges].languageId = languageId;
        }

        if (countryId) {
            this[pChanges].countryId = countryId;
        }

        if (countryStateId) {
            this[pChanges].countryStateId = countryStateId;
        }

        this[pStore].entityChanged(this);
    },

    [UploadableEntity.Callbacks.onUpload](api) {
        const changes = this[pChanges];

        this[pChanges] = null;

        api.context(changes);
    },

    __proto__: Entity,
};

export default Context;
