import { HTMLElement } from 'application-frame/core/nativePrototype';

import defineCustomElement from '../../lib/defineCustomElement';
import template from './template';

const HotIconMeta = {
    name: 'hot-icon',
    template,
    get object() { return HotIcon; },
};

const meta = HotIconMeta;

const HotIcon = {
    constructor: function HotHeader() {
        const instance = HTMLElement.constructor.apply(this);

        instance._create();

        return instance;
    },

    _create() {
        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(document.importNode(template.content, true));
    },

    connectedCallback() {
        this.setAttribute('resolved', '');
    },

    __proto__: HTMLElement,
};

defineCustomElement(meta);

export { HotIcon, HotIconMeta };
