import { Page, createView, defaultViewFactory } from '../prototypes/Page';
import { ComponentCallbacks } from '../prototypes/Component';
import Shopware from '../managers/Shopware';
import { ProductListingManager } from '../managers/ProductListing';
import { CartManager } from '../managers/Cart';
import { CurrencyManager } from '../managers/Currency';
import { LanguageManager } from '../managers/Language';
import { SubscriberFeature } from 'application-frame/core/features/SubscriberFeature';
import { Listing as ListingModel } from '../lib/shopware/Listing';
import { Context as ContextModel } from '../lib/shopware/Context';

const Callbacks = {
    onShopwareContextReady: Symbol('Listing.Callbacks.onShopwareContextReady'),
    onNavigationChanged: Symbol('Listing.Callbacks.onNavigationChanged'),
    onListingUpdate: Symbol('Listing.Callbacks.onListingUpdate'),
    onListingReady: Symbol('Listing.Callbacks.onListingReady'),
};

const pCategoryMap = Symbol('Listing.categoryMap');

export const Listing = {
    [pCategoryMap]: null,

    name: 'ListingPage',
    path: '/',
    template: 'page-listing',

    /** @type {ListingModel} */
    listing: null,
    serviceNavigation: null,
    mainNavigation: null,
    categories: null,

    /**
     * @type {ContextModel}
     */
    shopwareContext: null,

    /**
     * @type {string}
     */
    requestedLanguage: null,

    strings: {
        listingTitle: 'hotWaiter.listing.title',
        filterButtonLabel: 'listing.filterTitleText',
        copyright: 'footer.copyrightInfo',
    },

    init(template) {
        super.init(template);

        this[pCategoryMap] = new WeakMap();

        ProductListingManager.ready.then(this[Callbacks.onListingReady].bind(this));

        SubscriberFeature(CurrencyManager, this);

        Shopware.context.then(context => {
            this.shopwareContext = context;
            this.scope.update();
        });

        this.loadServiceNavigation();
        this.loadMainNavigation();
    },

    loadServiceNavigation() {
        Shopware.navigation(Shopware.Navigations.Service)
            .then(navigation => {
                this.serviceNavigation = Array.from(navigation);
                this.scope.update();
            });
    },

    loadMainNavigation() {
        this.categories = [];

        Shopware.navigation(Shopware.Navigations.Main, null, { buildTree: false })
            .then(nav => {
                this.mainNavigation = nav;
                this.mainNavigation.when(this[Callbacks.onNavigationChanged].bind(this));
            });

        this.scope.update();
    },

    [Callbacks.onListingReady](listing) {
        this.listing = listing;
        this.listing.when(this[Callbacks.onListingUpdate].bind(this));

        ProductListingManager.initialLoad(ProductListingManager.LoadReason.Listing);
    },

    [Callbacks.onNavigationChanged]() {
        this.categories = Array.from(this.mainNavigation)
            .filter(category => (category.type !== 'folder' && category.parentId));

        if (!(this.listing?.isEmpty ?? true)) {
            this[Callbacks.onListingUpdate]();
        }
    },

    [Callbacks.onListingUpdate]() {
        if (!this.categories?.length) {
            return;
        }

        this.categories.forEach(category => {
            this[pCategoryMap].set(category, new Set());
        });

        Array.from(this.listing.elements).forEach(product => {
            this.categories
                .filter(category => product.categoryTree.includes(category.id))
                .forEach(category => {
                    this[pCategoryMap].get(category).add(product);
                });
        });

        this.scope.update();
    },

    [CurrencyManager.Events.Change]() {
        this.scope.update();
    },

    /**
     * language change callback and consequent content refresh
     */
    [ComponentCallbacks.onL10nChanged]() {
        super[ComponentCallbacks.onL10nChanged]();

        this.requestedLanguage = null;

        if (!this.active || !this.scope) {
            return;
        }

        this.loadMainNavigation();
        this.loadServiceNavigation();
        ProductListingManager.refresh();
    },

    __proto__: Page,
};

createView((page) => {
    return {

        get nav() {
            return page.serviceNavigation;
        },

        navUrl(id) {
            return `#!/cms/${id}`;
        },

        get productCategories() {
            return page.categories ?? [];
        },

        getCategoryProducts(category) {
            const products = page[pCategoryMap]?.get(category);

            return products ? Array.from(products) : [];
        },

        hasEntries(category) {
            return page[pCategoryMap]?.get(category)?.size > 0;
        },

        hasNoEntries(category) {
            return !this.hasEntries(category);
        },

        productDetailsLink(id) {
            return `#!/product/${id}`;
        },

        productInfoLink(id) {
            return `#!/food-labels/${id}`;
        },

        productThumb(product) {
            const thumbnails = product.cover?.media?.thumbnails ?? [];

            for (const thumb of thumbnails) {
                if (thumb.width > 400) {
                    continue;
                }

                return thumb;
            }

            return null;
        },

        imgQuery(media) {
            return `(max-width: ${media.width}px)`;
        },

        properties(product) {
            return Array.from(product.properties).map(property => property.name).join(' | ');
        },

        currency(number) {
            return CurrencyManager.format(number);
        },

        onAddToCart(event, scope) {
            event.preventDefault();
            event.stopPropagation();

            CartManager.add(scope.product);
        },

        get selectedLanguage() {
            return page.requestedLanguage ?? LanguageManager.selectedLanguage?.value;
        },

        set selectedLanguage(value) {
            page.requestedLanguage = value;
            LanguageManager.switchLanguage(value);
        },

        get languages() {
            return LanguageManager.languages;
        },

        isSelectedLanguage(option) {
            return option.value === this.selectedLanguage;
        },

        __proto__: defaultViewFactory(page),
    };
}, Listing);

export default Listing;
