import { Component, createView, defaultViewFactory } from '../prototypes/Component';
import { RenderEngine } from 'application-frame/rendering';

const pOpen = Symbol('BottomSheet.open');
const pBusy = Symbol('BottomSheet.busy');

export const BottomSheet = {
    [pOpen]: false,
    [pBusy]: false,

    name: 'BottomSheet',
    template: 'component-bottom-sheet',
    closeObservers: null,

    get open() {
        return this[pOpen];
    },

    set open(value) {
        this[pOpen] = !!value;

        this.scope.update();
    },

    get busy() {
        return this[pBusy];
    },

    set busy(value) {
        this[pBusy] = !!value;

        this.scope.update();
    },

    setContent(nodes) {
        const target = document.querySelector(`#${this.id}`);


        if (!target) {
            RenderEngine.schedulePostRenderTask(() => this.setContent(nodes));

            return;
        }

        Array.from(target.children).forEach(child => {
            target.removeChild(child);
        });

        nodes.forEach(node => target.appendChild(node));
    },

    observeClose(listener) {
        this.closeObservers.add(listener);
    },

    new() {
        const instance = super.new();

        instance.closeObservers = new Set();

        return instance;
    },

    __proto__: Component,
};

createView((component) => {
    return {
        get id() { return component.id; },
        get isOpen() { return component[pOpen]; },
        set isOpen(value) { component[pOpen] = value; },

        get isBusy() { return component[pBusy]; },
        set isBusy(value) { component[pBusy] = value; },

        onClose(event) {
            component.closeObservers.forEach(listener => listener());

            event.preventRecycle();
        },

        __proto__: defaultViewFactory(component),
    };
}, BottomSheet);

export default BottomSheet;
