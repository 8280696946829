import { HTMLElement } from 'application-frame/core/nativePrototype';
import DataBinding from '@af-modules/databinding';
import Iterator from '../../lib/Iterator';

import defineCustomElement from '../../lib/defineCustomElement';
import template from './template';

const HotFormControlMeta = {
    name: 'hot-form-control',
    template,
    get object() { return HotFormControl; },

    attributes: [],
};

const meta = HotFormControlMeta;
const pScope = Symbol('HotFormControl.scope');
const pView = Symbol('HotFormControl.view');

const getInput = (host) => Iterator.new(host.children).find(child => child.matches('[slot="input"]'));


const createView = function(element) {
    return {
        touched: false,

        get isInvalid() {
            return this.touched && !getInput(element).validity.valid;
        },

        get validationError() {
            return getInput(element).validationMessage;
        },

        get showStatus() {
            return this.isInvalid;
        },

        onFocusControl() {
            getInput(element).focus();
        },

        onCheckValidity() {
            this.touched = true;
        },

        onInvalid() {}
    };
};

const HotFormControl = {
    [pScope]: null,
    [pView]: null,

    constructor: function HotHeader() {
        const instance = HTMLElement.constructor.apply(this);

        instance._create();

        return instance;
    },

    _create() {
        const view = createView(this);
        const { scope, node } = DataBinding.createTemplateInstance({ template, scope: view });

        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(node);

        this[pScope] = scope;
        this[pView] = view;
    },

    connectedCallback() {
        DataBinding.attachBindings(this[pScope], this, [
            { selector: 'root', name: 'bind-event', parameter: 'click', value: 'view.onFocusControl' },
            { selector: '[slot="input"]', name: 'bind-event', parameter: 'input', value: 'view.onCheckValidity' },
            { selector: '[slot="input"]', name: 'bind-event', parameter: 'blur', value: 'view.onCheckValidity' },
            { selector: '[slot="input"]', name: 'bind-event', parameter: 'invalid', value: 'view.onInvalid' },
        ]);

        this.setAttribute('resolved', '');
    },

    attributeChangedCallback() {
        this[pScope].update();
    },

    __proto__: HTMLElement,
};

defineCustomElement(meta);

export { HotFormControl, HotFormControlMeta };
