import EventTarget from 'application-frame/core/EventTarget';

import Shopware from './Shopware';

const Callbacks = {
    onContext: Symbol('CurrencyManager.Callbacks.onContext'),
    onLanguage: Symbol('CurrencyManager.Callbacks.onLanguage'),
};

export const CurrencyManager = {
    Events: {
        Change: Symbol('CurrencyManager.Events.Change'),
    },

    init() {
        this.constructor();
        this.createFormatter();

        Shopware.context.then(this[Callbacks.onContext].bind(this));
    },

    [Callbacks.onContext](context) {
        this.context = context;
        this.createFormatter();

        Shopware.language(context.salesChannel.languageId)
            .then(this[Callbacks.onLanguage].bind(this));
    },

    [Callbacks.onLanguage](language) {
        this.language = language;
        this.createFormatter();
    },

    createFormatter() {
        const update = !!this.formatter;
        const { translationCode: { code: language = navigator.language } = {} } = this.language ?? {};
        const { isoCode = 'USD', decimalPrecision = null } = this.context?.currency ?? {};

        this.formatter = new Intl.NumberFormat(language, {
            currency: isoCode,
            style: 'currency',
            minimumFractionDigits: decimalPrecision,
            maximumFractionDigits: decimalPrecision,
        });

        if (!update) {
            return;
        }

        this.emit(this.Events.Change);
    },

    /**
     * formats a number into a currency string
     *
     * @param  {number} value
     *
     * @return {string}
     */
    format(value) {
        const { symbol = '' } = this.context?.currency ?? {};

        return this.formatter
            .formatToParts(value)
            .map(part => {
                if (part.type === 'currency') {
                    part.value = symbol;
                }

                return part.value;
            }).join('');
    },

    formatToParts(value) {
        return this.formatter.formatToParts(value);
    },

    __proto__: EventTarget,
};

export default CurrencyManager;
