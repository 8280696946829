import EventTarget from 'application-frame/core/EventTarget';
import Shopware from './Shopware';
import Iterator from '../lib/Iterator';

const Callbacks = {
    onContextChange: Symbol('LanguageManager.Callbacks.onContextChange'),
};

const Events = {
    Change: Symbol('LanguageManager.Events.Change'),
    AvailableLanguages: Symbol('LanguageManager.Events.AvailableLanguages'),
};

const Private = {
    selectedLanguage: Symbol('LanguageManager.Private.selectedLanguage'),
    strings: Symbol('LanguageManager.Private.strings'),
    context: Symbol('LanguageManager.Private.context'),
};

export const LanguageManager = {
    Events,

    languages: null,

    [Private.strings]: null,
    [Private.context]: null,
    [Private.selectedLanguage]: null,

    get selectedLanguage() {
        return this[Private.selectedLanguage];
    },

    init() {
        this.constructor();

        Shopware.languages().then(languages => {
            this.languages = Iterator.new(languages)
                .map(lang => ({ id: lang.id, value: lang.translationCode.code, name: lang.name }))
                .intoArray();

            this.emit(Events.AvailableLanguages);
        });

        Shopware.context.then(context => {
            this[Private.context] = context;
            this[Private.context].when(this[Callbacks.onContextChange].bind(this));
        });
    },

    [Callbacks.onContextChange]() {
        const { salesChannel: { languageId } } = this[Private.context];

        if (this[Private.selectedLanguage]?.id === languageId) {
            return;
        }

        return Shopware.language(languageId)
            .then((language) => {
                const oldLang = this[Private.selectedLanguage];
                const newLang = { id: language.id, value: language.translationCode.code, name: language.name };

                this[Private.selectedLanguage] = newLang;

                return this.loadLanguage(newLang.value)
                    .then(() => {
                        this.emit(Events.Change, { old: oldLang, new: newLang });
                    });
            });
    },

    loadLanguage(code) {
        return import(`../../assets/lang/${code}.json`).then(strings => {
            this.strings = strings;
        });
    },

    switchLanguage(languageCode) {
        if (!this[Private.context]) {
            throw new Error('shopware context is not yet available! unable to switch language!');
        }

        if (typeof languageCode !== 'string') {
            return new TypeError('languageCode has to be of type string');
        }

        const languageId = this.languages.find(lang => lang.value === languageCode)?.id;

        if (!languageId) {
            throw new Error(`unknown language "${languageCode}"`);
        }

        return this[Private.context].modify({ languageId });
    },

    translate(key, variables = {}) {
        if (!this.strings?.[key]) {
            return key;
        }

        const findVars = /%([^%]+)%/g;
        let string = this.strings[key];

        Array.from(function*() {
            let result = null;

            while ((result = findVars.exec(string))) {
                yield result[1];
            }

            return result;
        }()).forEach(name => {
            if (!variables[name]) {
                return;
            }

            string = string.replace(`%${name}%`, variables[name]);
        });

        return string;
    },

    __proto__: EventTarget,
};

export default LanguageManager;
