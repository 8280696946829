import { Entity } from './Entity';

export const CalculatedPrice = {

    get unit() {
        return this.value?.unitPrice;
    },

    get quantity() {
        return this.value?.quantity;
    },

    get total() {
        return this.value?.totalPrice;
    },

    get reference() {
        return this.value?.referencePrice;
    },

    get list() {
        return this.value?.listPrice;
    },

    __proto__: Entity,
};

export default CalculatedPrice;
