const Callbacks = {
    onUpload: Symbol('UploadableEntity.Callbacks.onUpload'),
};

export const UploadableEntity = {
    Callbacks,
    isDirty: Symbol('UploadableEntity.Dirty'),
};

export { Callbacks as UploadableEntityCallbacks, UploadableEntity as default };
