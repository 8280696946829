/**
 * Automatically subscribes an object to all implemented events
 * of the supplied event emitters.
 *
 * @deprecated use SubscriberFeature of application-frame@>1.1.0
 *
 * @param  {(object|object[])} emitters
 * @param  {object} host
 *
 * @return {undefined}
 */
export const EventSubscriberFeature = function(emitters, host) {
    emitters = Array.isArray(emitters) ? emitters : [emitters];

    emitters.forEach(emitter => {
        if (!emitter.on || !emitter.Events) {
            throw new Error('suplied event emitter does not support auto subscription!');
        }

        Object.values(emitter.Events).forEach(event => {
            if (!host[event]) {
                return;
            }

            emitter.on(event, host[event].bind(host));
        });
    });
};

export default EventSubscriberFeature;
